import {
  ON_POST_USER_ATTRIBUTES,
  ON_SET_USER,
  UserActionsTypes,
  UserInterface
} from "./user.actions";

const defaultState: UserInterface = {
  cognitoUser: undefined,
  userAttributes: {},
  cognitoGroups: []
};

// [Question] Defining return type of a whole reducer/state aprt
export default (
  state = defaultState,
  action: UserActionsTypes
): UserInterface => {
  switch (action.type) {
    case ON_POST_USER_ATTRIBUTES: {
      const { userAttributes } = action;
      // formatar userAttributes?
      return {
        ...state,
        userAttributes: userAttributes,
      };
    }
    case ON_SET_USER: {
      const { cognitoUser, userAttributes, cognitoGroups } = action;
      
      return {
        ...state,
        cognitoUser: cognitoUser,
        userAttributes: userAttributes,
        cognitoGroups: cognitoGroups
      };
    }
    default:
      return state;
  }
};
