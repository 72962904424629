import React, {
  useState,
  forwardRef,
  LegacyRef,
  createRef,
  useEffect,
  useContext,
} from "react";
import classNames from "classnames";
import SlideDown from "react-slidedown";
import { createPopper } from "@popperjs/core";
import ResizeObserver from "resize-observer-polyfill";
import { SidebarContext } from "../ProSidebar";

export interface Props {
  id?: string;
  children?: React.ReactNode;
  className?: string;
  icon?: React.ReactNode;
  title?: React.ReactNode;
  defaultOpen?: boolean;
  open?: boolean;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  firstchild?: boolean;
  popperarrow?: boolean;
  //| React.KeyboardEvent
  onClick?:
    | ((event: React.MouseEvent) => void)
    | ((event: React.KeyboardEvent) => void);
  onDrop?: (event: React.DragEvent<HTMLDivElement>) => void;
  onDragStart?: (event: React.DragEvent<HTMLDivElement>) => void;
}

const SubMenu: React.ForwardRefRenderFunction<unknown, Props> = (
  {
    id,
    children,
    icon,
    className,
    title,
    defaultOpen = false,
    open,
    prefix,
    suffix,
    firstchild,
    popperarrow,
    onClick,
    onDrop,
    onDragStart,
    ...rest
  }: Props,
  ref
) => {
  const { collapsed, rtl, toggled } = useContext(SidebarContext);
  const [closed, setClosed] = useState(!defaultOpen);
  const [draggingOver, setDraggingOver] = useState(false);

  const popperElRef: React.RefObject<HTMLDivElement> = createRef<
    HTMLDivElement
  >();
  const referenceElement: React.RefObject<HTMLDivElement> = createRef<
    HTMLDivElement
  >();
  const popperElement: React.RefObject<HTMLDivElement> = createRef<
    HTMLDivElement
  >();

  const handleToggleSubMenu = () => {
    setClosed(!closed);
  };

  useEffect(() => {
    let popperInstance: any;

    if (firstchild) {
      if (collapsed) {
        if (
          referenceElement &&
          referenceElement.current &&
          popperElement.current
        ) {
          popperInstance = createPopper(
            referenceElement.current,
            popperElement.current,
            {
              placement: "right",
              strategy: "fixed",
              modifiers: [
                {
                  name: "computeStyles",
                  options: {
                    adaptive: false,
                  },
                },
              ],
            }
          );
        }

        if (popperElement && popperElRef.current && referenceElement.current) {
          const ro = new ResizeObserver(() => {
            if (popperInstance) {
              popperInstance.update();
            }
          });

          ro.observe(popperElRef.current);
          ro.observe(referenceElement.current);
        }

        setTimeout(() => {
          if (popperInstance) {
            popperInstance.update();
          }
        }, 300);
      }
    }

    return () => {
      if (popperInstance) {
        popperInstance.destroy();
        popperInstance = null;
      }
    };
  }, [
    collapsed,
    rtl,
    toggled,
    firstchild,
    popperElRef,
    popperElement,
    referenceElement,
  ]);

  const onActivateMenu = (e: any) => {
    handleToggleSubMenu();
    if (onClick) onClick(e);
  };
  const subMenuRef: LegacyRef<HTMLLIElement> =
    (ref as any) || React.createRef<HTMLLIElement>();

  return (
    <li
      ref={subMenuRef}
      className={classNames("pro-menu-item pro-sub-menu", className, {
        open: typeof open === "undefined" ? !closed : open,
      })}
    >
      <div
        {...rest}
        id={id}
        ref={referenceElement}
        className={classNames(
          icon ? "pro-inner-item with-icon" : "pro-inner-item",
          draggingOver ? "dragging-over" : null
        )}
        onClick={onActivateMenu}
        onKeyPress={onActivateMenu}
        draggable="true"
        onDragStart={onDragStart}
        onDragOver={(e) => {
          e.preventDefault();
          setDraggingOver(true);
        }}
        onDragLeave={() => setDraggingOver(false)}
        onDrop={(e: React.DragEvent<HTMLDivElement>) => {
          setDraggingOver(false);
          if (onDrop) onDrop(e);
        }}
        role="button"
        tabIndex={0}
      >
        {icon ? (
          <span className="pro-icon-wrapper">
            <span className="pro-icon">{icon}</span>
          </span>
        ) : null}
        {prefix ? <span className="prefix-wrapper">{prefix}</span> : null}
        <span className="pro-item-content">{title}</span>
        {suffix ? <span className="suffix-wrapper">{suffix}</span> : null}
        <span className="pro-arrow-wrapper">
          <span className="pro-arrow" />
        </span>
      </div>

      {firstchild && collapsed ? (
        <div
          ref={popperElement}
          className={classNames("pro-inner-list-item popper-element", {
            "has-arrow": popperarrow,
          })}
        >
          <div className="popper-inner" ref={popperElRef}>
            <ul>{children}</ul>
          </div>
          {popperarrow ? (
            <div className="popper-arrow" data-popper-arrow />
          ) : null}
        </div>
      ) : (
        <SlideDown
          closed={typeof open === "undefined" ? closed : !open}
          className="pro-inner-list-item"
        >
          <div>
            <ul>{children}</ul>
          </div>
        </SlideDown>
      )}
    </li>
  );
};

export default forwardRef<unknown, Props>(SubMenu);
