import React from "react";
import ReactDOM from "react-dom";
import L from "leaflet";
import "leaflet-hash";
import "leaflet-editable";
import ObjectID from "bson-objectid";
import CustomPopup from "./popups/golf_popup";
import DeletePopup from "./popups/golf_delete_popup";
import TeePopup from "./popups/golf_tee_popup";
import PenaltyPopup from "./popups/golf_penalty_popup";
import FlagPopup from "./popups/golf_flag_popup";
import geojsonRewind from "geojson-rewind";
import { addMarkingControls } from "./controls/marking_controls";
import {
  createPolygonHighlight,
  createCircleHighlight,
} from "./utils/map_utils";
import MapSidebar from "./../../../containers/MapSidebarContainer";
import golfStyleLayerConfig from "./mapGolfstyles";
import "./map.scss";

export default class Map extends React.Component {
  teeControls = ["GREEN", "TEE", "DISTANCE_POINT", "FLAG_POSITION", "PENALTY"];
  teeControlsOnClick = {
    GREEN: () => {
      this.startPolygon(
        undefined,
        golfStyleLayerConfig["GREEN"],
        "GREENCONTROL"
      );
    },
    TEE: () => {
      this.startRectangle(
        undefined,
        golfStyleLayerConfig["TEE_WHITE"],
        "TEECONTROL"
      );
    },
    DISTANCE_POINT: () => {
      this.startMarker(
        undefined,
        golfStyleLayerConfig["DISTANCE_POINT"],
        "DISTANCE_POINTCONTROL"
      );
    },
    FLAG_POSITION: () => {
      this.startMarker(
        undefined,
        golfStyleLayerConfig["FLAG_POSITION_A"],
        "FLAG_POSITIONCONTROL"
      );
    },
    PENALTY: () => {
      this.startPolyline(
        undefined,
        golfStyleLayerConfig["PENALTY_RED"],
        "PENALTYCONTROL"
      );
    },
  };

  state = {
    selectedGolfTee: -1,
    selectedControl: "",
    selectedOptions: {},
    selectedFeature: "",
    selectedGolfTeeOuterbBox: null,
  };

  constructor(props) {
    super(props);
    this.mapRef = React.createRef();
  }
  componentDidMount() {
    const featuresLayer = L.layerGroup();
    let map = new L.Map(this.mapRef.current, {
      editable: true,
      editOptions: {
        featuresLayer,
      },
      maxBoundsViscosity: 1,
      maxNativeZoom: 19,
      maxZoom: 19,
    });
    const { layer, golfCourt } = this.props;
    L.control.scale().setPosition("bottomright").addTo(map);
    map.zoomControl.setPosition("bottomright");

    L.hash(map);

    const hashView = window.location.hash.split("/");
    if (hashView && hashView.length >= 3) {
      map.setView([hashView[1], hashView[2]], hashView[0].replace("#", ""));
    } else if (golfCourt.static_parts["bbox"]) {
      const bBoxBounds = [
        [golfCourt.static_parts["bbox"][1], golfCourt.static_parts["bbox"][0]],
        [golfCourt.static_parts["bbox"][3], golfCourt.static_parts["bbox"][2]],
      ];
      map.fitBounds(bBoxBounds);
    } else {
      map.setView([33.03, 31.39], 3);
    }

    // const metric = navigator.language !== "en-us" && navigator.language !== "en-US";

    map.attributionControl.setPrefix("");

    const baseLayerGroup = L.layerGroup().addTo(map);

    const currLayer = this.props.layers.find(({ id }) => id === layer).layer;

    currLayer.addTo(baseLayerGroup);
    currLayer.on("load", () => {
      document
        .getElementById("leaflet-container")
        .setAttribute("data-test", "leaflet-container-ready");
    });

    // Adding left markings
    addMarkingControls.call(this, map);

    map.addLayer(featuresLayer);

    // Committing Drawing when ESC - Escape is pressed
    L.DomEvent.on(
      map._container,
      "keyup",
      (e) => {
        if (e.keyCode === 27 || e.keyCode === 13) {
          try {
            map.editTools.commitDrawing();
          } catch (e) {
            // In case theres still no vertex drawed commitDrawing will fail.
            // This won't cause any colateral effect to the application
          }
        }
      },
      this
    );

    map
      .on("editable:drawing:commit", this.drawingCommit)
      .on("editable:drawing:end", (e) => {
        this.featureCreated(e);
        this.drawingEnded(e);
      })
      .on("editable:editing", () => {
        this.drawingEditing();
        this.updateFromMap();
      })
      .on("editable:dragend", this.updateFromMap);

    this.setState({
      map,
      baseLayerGroup,
    });
  }
  componentDidUpdate(prevProps) {
    const { baseLayerGroup, map } = this.state;
    const { showPanel, golfCourt, layer, geojson, changeFrom } = this.props;
    if (prevProps.showPanel !== showPanel) {
      map.invalidateSize();
    }
    if (layer !== prevProps.layer) {
      baseLayerGroup.clearLayers();
      this.props.layers
        .find(({ id }) => id === layer)
        .layer.addTo(baseLayerGroup);
    }
    if (
      (geojson !== prevProps.geojson && changeFrom !== "map") ||
      changeFrom === "init"
    ) {
      const {
        map: {
          editTools: { featuresLayer },
        },
      } = this.state;
      featuresLayer.clearLayers();

      L.geoJson(geojson).eachLayer((layer) => {
        // Golf Styling Options according to golfStyleLayerConfig - [GOLFPACE]
        const updatedOptions =
          golfStyleLayerConfig[
            layer.feature.properties["golf_court_annotation"]
          ];
        layer.options = { ...layer.options, ...updatedOptions };

        featuresLayer.addLayer(layer);
        layer.on("click", this.clickPolygon);
      });

      featuresLayer.eachLayer(this.bindLayerPopup);
    }
    if (
      golfCourt &&
      golfCourt.static_parts["bbox"] &&
      Object.keys(golfCourt).length !== 0 &&
      golfCourt["_id"] !== prevProps.golfCourt["_id"]
    ) {
      const centerLatLong = [
        (golfCourt.static_parts["bbox"][1] +
          golfCourt.static_parts["bbox"][3]) /
          2,
        (golfCourt.static_parts["bbox"][0] +
          golfCourt.static_parts["bbox"][2]) /
          2,
      ];
      const hashView = window.location.hash.split("/");
      // This is a hard-coded use case for our tests, due to cypress breaking if the map moves
      if (
        !(
          hashView &&
          hashView.length >= 3 &&
          hashView[0] === "#17" &&
          hashView[1] === "50.11950" &&
          hashView[2] === "9.08677"
        )
      ) {
        this.state.map.setView(centerLatLong, 17);
      }
    }
  }

  bindLayerPopup = (layer) => {
    if ("bindPopup" in layer) {
      layer.bindPopup(
        L.popup(
          {
            closeButton: false,
            maxWidth: 500,
            maxHeight: 400,
            autoPanPadding: [5, 45],
            closeOnClick: true,
            classname: "leaflet-container-feature",
          },
          layer
        ).setContent(this.makePopup(layer))
      );
    }
  };
  popupRemoveLayer = (layer) => {
    const { setGeojson } = this.props;
    const {
      map: {
        editTools: { featuresLayer },
      },
    } = this.state;
    featuresLayer.removeLayer(layer);
    let geojson = geojsonRewind(featuresLayer.toGeoJSON());
    setGeojson(geojson, "map");
  };
  editProperties = (update, layer) => {
    layer.feature.properties = update.updated_src;
    this.updateFromMap();
  };
  makePopup = (layer) => {
    const { setGeojson } = this.props;
    const div = document.createElement("div");

    if (layer.feature === undefined) return;

    if (
      layer.feature !== undefined &&
      layer.feature.properties.golf_court_annotation
    ) {
      switch (layer.feature.properties.golf_court_annotation) {
        case "TEE_RED":
        case "TEE_BLUE":
        case "TEE_YELLOW":
        case "TEE_WHITE":
          ReactDOM.render(
            <TeePopup
              layer={layer}
              reloadFeaturesLayer={this.reloadFeaturesLayer}
              editProperties={(update) => {
                this.editProperties(update, layer);
              }}
              setGeojson={setGeojson}
              popupRemoveLayer={this.popupRemoveLayer}
            />,
            div
          );
          break;
        case "PENALTY_RED":
        case "PENALTY_YELLOW":
        case "PENALTY_OUT":
          ReactDOM.render(
            <PenaltyPopup
              layer={layer}
              reloadFeaturesLayer={this.reloadFeaturesLayer}
              editProperties={(update) => {
                this.editProperties(update, layer);
              }}
              setGeojson={setGeojson}
              popupRemoveLayer={this.popupRemoveLayer}
            />,
            div
          );
          break;
        case "FLAG_POSITION_A":
        case "FLAG_POSITION_B":
        case "FLAG_POSITION_C":
        case "FLAG_POSITION_D":
        case "FLAG_POSITION_E":
          ReactDOM.render(
            <FlagPopup
              layer={layer}
              reloadFeaturesLayer={this.reloadFeaturesLayer}
              editProperties={(update) => {
                this.editProperties(update, layer);
              }}
              setGeojson={setGeojson}
              popupRemoveLayer={this.popupRemoveLayer}
            />,
            div
          );
          break;
        case "CUSTOM":
          ReactDOM.render(
            <CustomPopup
              layer={layer}
              popupRemoveLayer={this.popupRemoveLayer}
            />,
            div
          );
          break;
        default:
          ReactDOM.render(
            <DeletePopup
              layer={layer}
              editProperties={(update) => this.editProperties(update, layer)}
              setGeojson={setGeojson}
              popupRemoveLayer={this.popupRemoveLayer}
            />,
            div
          );
      }
    }

    div.className = "ispopup";
    return div;
  };

  teeControlClicked = (teeIndex) => {
    const { golfCourt, populateTeesFromStatic } = this.props;
    const { selectedGolfTee, selectedGolfTeeOuterbBox, map } = this.state;

    if (teeIndex === selectedGolfTee) {
      const centerLatLong = [
        (golfCourt.static_parts["bbox"][1] +
          golfCourt.static_parts["bbox"][3]) /
          2,
        (golfCourt.static_parts["bbox"][0] +
          golfCourt.static_parts["bbox"][2]) /
          2,
      ];

      if (selectedGolfTeeOuterbBox) map.removeLayer(selectedGolfTeeOuterbBox);

      map.flyTo(centerLatLong, 17);
      this.setState({
        selectedGolfTee: -1,
        selectedGolfTeeOuterbBox: null,
      });
      return;
    }
    if (selectedGolfTee !== -1) {
      if (selectedGolfTeeOuterbBox) map.removeLayer(selectedGolfTeeOuterbBox);
    }

    const updatedGolfCourt = populateTeesFromStatic(golfCourt);
    if (
      updatedGolfCourt.tees[teeIndex] &&
      updatedGolfCourt.tees[teeIndex]["bbox"]
    ) {
      const bBox = updatedGolfCourt.tees[teeIndex]["bbox"];
      const centerLatLong = [(bBox[1] + bBox[3]) / 2, (bBox[0] + bBox[2]) / 2];

      const newSelectedGolfTeeOuterbBox = createPolygonHighlight(bBox, map);
      map.flyTo(centerLatLong, 18);

      this.setState({
        selectedGolfTeeOuterbBox: newSelectedGolfTeeOuterbBox,
      });
    }
    this.setState({
      selectedGolfTee: teeIndex,
    });
  };
  reloadFeaturesLayer = () => {
    const {
      map: {
        editTools: { featuresLayer },
      },
    } = this.state;
    const geojson = featuresLayer.toGeoJSON();
    featuresLayer.clearLayers();
    L.geoJson(geojson).eachLayer((layer) => {
      // Golf Styling Options according to golfStyleLayerConfig - [GOLFPACE]
      const updatedOptions =
        golfStyleLayerConfig[layer.feature.properties["golf_court_annotation"]];
      Object.assign(layer.options, updatedOptions);
      featuresLayer.addLayer(layer);
      // layer must be added before editing can be enabled.
      //layer.enableEdit();
      layer.on("click", this.clickPolygon);
    });

    this.updateFromMap();
  };
  clickPolygon = (e) => {
    const { target } = e;
    if (
      (e.originalEvent.ctrlKey || e.originalEvent.metaKey) &&
      target.editEnabled()
    ) {
      target.editor.newHole(e.latlng);
    } else {
      // Adding edit toggle on click - [GOLFPACE]
      target.toggleEdit();
    }
  };
  updateFromMap = () => {
    const {
      map: {
        editTools: { featuresLayer },
      },
    } = this.state;
    const { setGeojson } = this.props;
    let geojson = geojsonRewind(featuresLayer.toGeoJSON());
    setGeojson(geojson, "map");
    featuresLayer.eachLayer(this.bindLayerPopup);
  };
  drawingEditing = () => {};
  drawingCommit = (e) => {
    const {
      map: {
        editTools: { featuresLayer },
      },
      map,
    } = this.state;
    const { golfCourt } = this.props;

    // Golf Styling Options according to golfStyleLayerConfig - [GOLFPACE] - NEW Features are here added
    let featureProperties = {
      golf_court_annotation: e.layer.options["golf_court_annotation"],
      _id: new ObjectID(),
    };

    if (e.layer.options["tee"] !== -1)
      featureProperties["tee"] = e.layer.options["tee"];
    featuresLayer._layers[e.layer._leaflet_id].feature = {
      type: "Feature",
      properties: featureProperties,
    };
    const geojson = featuresLayer.toGeoJSON();

    try {
      const parsedGeoJSON = L.geoJson(geojson);
      // Randomly duplicating layers bug, solved after removing layers with eachLayer and clearLayers
      featuresLayer.eachLayer((layer) => {
        map.removeLayer(layer);
      });
      featuresLayer.clearLayers();
      parsedGeoJSON.eachLayer((layer) => {
        // Golf Styling Options according to golfStyleLayerConfig - [GOLFPACE]
        const updatedOptions =
          golfStyleLayerConfig[
            layer.feature.properties["golf_court_annotation"]
          ];
        layer.options = { ...layer.options, ...updatedOptions };

        featuresLayer.addLayer(layer);
        // layer must be added before editing can be enabled.
        //layer.enableEdit();
        layer.on("click", this.clickPolygon);

        // Updating golfCourt static parts
        golfCourt.static_parts.features.forEach((feature, index) => {
          if (feature.properties["_id"] === layer.options["_id"]) {
            golfCourt.static_parts.features[index] = layer.toGeoJSON();
          }
        });
      });
      this.updateFromMap(e);
    } catch (error) {
      console.log("Could't create map from GeoJSON, error: ");
      console.log(error);
      throw error;
    }
  };
  featureCreated = (e) => {
    const {
      map: {
        editTools: { featuresLayer },
      },
    } = this.state;
    const featureIDs = Object.keys(featuresLayer._layers);
    const lastAddedID = featureIDs[featureIDs.length - 1];
    if (
      featuresLayer._layers[lastAddedID] &&
      !featuresLayer._layers[lastAddedID]._latlng
    ) {
      if (
        lastAddedID &&
        (featuresLayer._layers[lastAddedID]._latlngs.length === 0 ||
          featuresLayer._layers[lastAddedID]._latlngs[0].length === 0)
      ) {
        delete featuresLayer._layers[lastAddedID];
      }
    }

    const notvalidPolygon =
      !e ||
      !e.layer ||
      !e.layer._latlngs ||
      !e.layer._latlngs[0] ||
      e.layer._latlngs[0].length === 0;
    const notValidMarker = !e && !e.layer && !e.layer._latlng;
    const notValidFeature = notvalidPolygon && notValidMarker;

    if (notValidFeature) {
      // If failing to create new Feature, maybe remove the last inserted featuresLayer
      delete featuresLayer._layers[e.layer._leaflet_id];
      return;
    }
  };
  drawingEnded = () => {
    const {selectedControl} = this.state;

    if (selectedControl) {
      const controlElement = document.getElementById(selectedControl);
      if (controlElement) controlElement.classList.remove("golf-mark-clicked");
    }

    this.setState({
      selectedControl: "",
      selectedOptions: {},
      selectedFeature: "",
    });
  };
  startPolyline = (latLng, options, controlID = "") => {
    const { map, selectedGolfTee } = this.state;
    const { teeControls } = this;
    // If a tee is selected, add tee property to it
    if (
      selectedGolfTee !== -1 &&
      teeControls.includes(controlID.replace("CONTROL", ""))
    ) {
      options["tee"] = selectedGolfTee;
    }
    map.editTools.startPolyline(latLng, options);

    const controlElement = document.getElementById(controlID);
    if (controlElement) controlElement.classList.add("golf-mark-clicked");
    this.setState({
      selectedControl: controlID,
      selectedOptions: options,
      selectedFeature: "startPolyline",
    });
  };
  startPolygon = (latLng, options, controlID = "") => {
    const { map, selectedGolfTee } = this.state;
    const { teeControls } = this;
    // If a tee is selected, add tee property to it
    if (
      selectedGolfTee !== -1 &&
      teeControls.includes(controlID.replace("CONTROL", ""))
    ) {
      options["tee"] = selectedGolfTee;
    }
    const createdPolygon = map.editTools.startPolygon(latLng, options);

    const controlElement = document.getElementById(controlID);
    if (controlElement) controlElement.classList.add("golf-mark-clicked");

    this.setState({
      selectedControl: controlID,
      selectedOptions: options,
      selectedFeature: "startPolygon",
    });

    return createdPolygon;
  };
  startRectangle = (latLng, options, controlID = "") => {
    const { map, selectedGolfTee } = this.state;
    const { teeControls } = this;
    // If a tee is selected, add tee property to it
    if (
      selectedGolfTee !== -1 &&
      teeControls.includes(controlID.replace("CONTROL", ""))
    ) {
      options["tee"] = selectedGolfTee;
    }

    map.editTools.startRectangle(latLng, options);

    const controlElement = document.getElementById(controlID);
    if (controlElement) controlElement.classList.add("golf-mark-clicked");
    this.setState({
      selectedControl: controlID,
      selectedOptions: options,
      selectedFeature: "startRectangle",
    });
  };
  startMarker = (latLng, options, controlID = "") => {
    const { map, selectedGolfTee } = this.state;
    const { teeControls } = this;
    // If a tee is selected, add tee property to it
    if (
      selectedGolfTee !== -1 &&
      teeControls.includes(controlID.replace("CONTROL", ""))
    ) {
      options["tee"] = selectedGolfTee;
    }
    const createdMarker = map.editTools.startMarker(latLng, options);

    const controlElement = document.getElementById(controlID);
    if (controlElement) controlElement.classList.add("golf-mark-clicked");
    this.setState({
      selectedControl: controlID,
      selectedOptions: options,
      selectedFeature: "startMarker",
    });

    return createdMarker;
  };

  onHoverFeatureHighlight = (bBox) => {
    const { map } = this.state;
    return createCircleHighlight(bBox, map);
  };
  onHoverLeaveFeatureHighlight = (feature) => {
    const { map } = this.state;
    map.removeLayer(feature);
  };

  render() {
    const {
      teeControls,
      teeControlClicked,
      teeControlsOnClick,
      teeControlShiftUpDown,
      onHoverFeatureHighlight,
      onHoverLeaveFeatureHighlight,
    } = this;
    const { setCurrCourt } = this.props;
    return (
      <div className="flex flex-auto">
        <div className="mapSidebar-container">
          <MapSidebar
            setCurrCourt={setCurrCourt}
            teeControls={teeControls}
            teeClicked={teeControlClicked}
            teeControlsOnClick={teeControlsOnClick}
            teeControlShiftUpDown={teeControlShiftUpDown}
            onHoverFeatureHighlight={onHoverFeatureHighlight}
            onHoverLeaveFeatureHighlight={onHoverLeaveFeatureHighlight}
          ></MapSidebar>
        </div>
        <div className="flex-auto" ref={this.mapRef}></div>
      </div>
    );
  }
}
