import React from "react";

const buttons = [
  {
    mode: "code",
    title: "GeoJSON",
  },
  {
    mode: "help",
    title: "Info",
  },
];

export default function ModeButtons({ mode, setMode }) {
  return (
    <div className="inline-flex">
      {buttons.map((button, i) => (
        <span
          key={i}
          className={`db bn pa2 outline-0 pointer black
                  ${mode === button.mode ? "bg-yellow" : ""}`}
          onClick={() => setMode(button.mode)}
        >
          {button.title}
        </span>
      ))}
    </div>
  );
}
