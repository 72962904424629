import shpwrite from "shp-write";
import wkx from "wkx";
import geojson2dsv from "geojson2dsv";
import togpx from "togpx";
import polyline from "@mapbox/polyline";
import * as topojson from "topojson";
import { saveAs } from "file-saver";
import tokml from "tokml";
import geojsonNormalize from "@mapbox/geojson-normalize";
import simplify from "@turf/simplify";
import wellknown from "wellknown";
import magicFile from "../../../utils/magic_file";
import geojsonRandom from "geojson-random";
import geojsonExtent from "geojson-extent";
import React from "react";
import geojsonFlatten from "geojson-flatten";
import mergeGeojson from "../../../utils/merge_geojson";
import { Auth } from "aws-amplify";
import DropdownIcon from "./../../../img/dropdown-icon.svg";
import "./navbar.css";

export default class NavBar extends React.Component {
  constructor() {
    super();
    this.fileInputRef = React.createRef();
  }
  blindImport = () => {
    this.fileInputRef.current.click();
  };
  onFileInputChange = async (e) => {
    const { files } = e.target;
    const { geojson, setGeojson } = this.props;
    const geojsons = await Promise.all(
      [...files].map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsText(file);
          reader.addEventListener("load", () =>
            resolve(magicFile(reader.result))
          );
        });
      })
    );
    setGeojson(mergeGeojson([geojson, ...geojsons]));
  };
  downloadTopo = () => {
    const { geojson } = this.props;
    var content = JSON.stringify(
      topojson.topology(
        {
          collection: JSON.parse(JSON.stringify(geojson)),
        },
        {
          "property-transform": function (properties, key, value) {
            properties[key] = value;
            return true;
          },
        }
      )
    );

    this.download(content, "map.topojson", "text/plain;charset=utf-8");
  };

  download = (content, filename, type) => {
    saveAs(
      new Blob([content], {
        type,
      }),
      filename
    );
  };

  downloadGPX = () => {
    const { geojson } = this.props;
    this.download(
      togpx(geojson, {
        creator: "geojson.net",
      }),
      "map.gpx",
      "text/xml;charset=utf-8"
    );
  };

  downloadGeoJSON = () => {
    const { geojson } = this.props;
    this.download(
      JSON.stringify(geojson, null, 2),
      "map.geojson",
      "text/plain;charset=utf-8"
    );
  };

  downloadDSV = () => {
    const { geojson } = this.props;
    this.download(
      geojson2dsv(geojson),
      "points.csv",
      "text/plain;charset=utf-8"
    );
  };

  downloadKML = () => {
    const { geojson } = this.props;
    this.download(tokml(geojson), "map.kml", "text/plain;charset=utf-8");
  };

  downloadShp = () => {
    const { geojson } = this.props;
    shpwrite.download(geojson);
  };

  downloadWKT = () => {
    const { geojson } = this.props;
    var features = geojson.features;
    if (features.length === 0) return;
    var content = features.map(wellknown.stringify).join("\n");
    this.download(content, "map.wkt", "text/plain;charset=utf-8");
  };

  golfCourtsDropdown = (allCourts) => {
    const { switchGolfCourt } = this.props;
    const allCourtsDropdown = [];
    allCourts.forEach((golfCourt) => {
      allCourtsDropdown.push(
        <div
          onClick={() => {
            switchGolfCourt(golfCourt._id);
          }}
          key={golfCourt._id}
          className={`bn bg-white bg-animate hover-bg-near-white ph2 br3 tc gray normal pointer`}
        >
          {golfCourt.name}
        </div>
      );
    });
    return allCourtsDropdown;
  };

  render() {
    const {
      setGeojson,
      saveGolfCourtToDB,
      user,
      currCourt,
      allCourts,
    } = this.props;
    const exportFormats = [
      {
        title: "GeoJSON",
        action: this.downloadGeoJSON,
      },
      {
        title: "TopoJSON",
        action: this.downloadTopo,
      },
      {
        title: "GPX",
        action: this.downloadGPX,
      },
      {
        title: "CSV",
        action: this.downloadDSV,
      },
      {
        title: "Shapefile",
        action: this.downloadShp,
      },
      {
        title: "KML",
        action: this.downloadKML,
      },
      {
        title: "WKT",
        action: this.downloadWKT,
      },
    ];
    var actions = [
      {
        title: "Save",
        children: exportFormats,
      },
      {
        title: "New",
        action: function () {
          window.open(
            window.location.origin + window.location.pathname + "#new"
          );
        },
      },
      {
        title: "Meta",
        action: function () {},
        children: [
          {
            title: "Clear",
            alt: "Delete all features from the map",
            action: () => {
              if (
                window.confirm(
                  "Are you sure you want to delete all features from this map?"
                )
              ) {
                setGeojson({ type: "FeatureCollection", features: [] });
              }
            },
          },
          {
            title: "Random: Points",
            alt: "Add random points to your map",
            action: () => {
              const { setGeojson, geojson } = this.props;
              var response = prompt("Number of points (default: 100)");
              if (response === null) return;
              var count = parseInt(response, 10);
              if (isNaN(count)) count = 100;
              const fc = geojsonNormalize(geojson);
              setGeojson({
                type: "FeatureCollection",
                features: fc.features.concat(
                  geojsonRandom.point(count).features
                ),
              });
            },
          },
          {
            title: "Add bboxes",
            alt: "Add bounding box members to all applicable GeoJSON objects",
            action: () => {
              const { setGeojson, geojson } = this.props;
              setGeojson(geojsonExtent.bboxify(geojson));
            },
          },
          {
            title: "Simplify",
            alt: "Remove unnecessary detail from GeoJSON features",
            action: () => {
              const { setGeojson, geojson } = this.props;
              setGeojson(simplify(geojson));
            },
          },
          {
            title: "Flatten Multi Features",
            alt:
              "Flatten MultiPolygons, MultiLines, and GeometryCollections into simple geometries",
            action: () => {
              const { setGeojson, geojson } = this.props;
              setGeojson(geojsonFlatten(geojson));
            },
          },
          // https://developers.google.com/maps/documentation/utilities/polylinealgorithm
          {
            title: "Load encoded polyline",
            alt:
              "Decode and show an encoded polyline. Precision 5 is supported.",
            action: () => {
              const { setGeojson } = this.props;
              const input = prompt("Enter your polyline");
              try {
                const decoded = polyline.toGeoJSON(input);
                setGeojson(decoded);
              } catch (e) {
                alert("Sorry, we were unable to decode that polyline");
              }
            },
          },
          {
            title: "Load WKB Base64 Encoded String",
            alt: "Decode and show WKX data",
            action: () => {
              const input = prompt("Enter your Base64 encoded WKB/EWKB");
              try {
                // TODO: base64 in browser
                var decoded = wkx.Geometry.parse(Buffer.from(input, "base64"));
                setGeojson(decoded.toGeoJSON());
              } catch (e) {
                console.error(e);
                alert(
                  "Sorry, we were unable to decode that Base64 encoded WKX data"
                );
              }
            },
          },
          {
            title: "Load WKB Hex Encoded String",
            alt: "Decode and show WKX data",
            action: function () {
              const input = prompt("Enter your Hex encoded WKB/EWKB");
              try {
                var decoded = wkx.Geometry.parse(Buffer.from(input, "hex"));
                setGeojson(decoded.toGeoJSON());
              } catch (e) {
                console.error(e);
                alert(
                  "Sorry, we were unable to decode that Hex encoded WKX data"
                );
              }
            },
          },
          {
            title: "Load WKT String",
            alt: "Decode and show WKX data",
            action: function () {
              const input = prompt("Enter your WKT/EWKT String");
              try {
                var decoded = wkx.Geometry.parse(input);
                setGeojson(decoded.toGeoJSON());
              } catch (e) {
                console.error(e);
                alert("Sorry, we were unable to decode that WKT data");
              }
            },
          },
        ],
      },
    ];

    actions.unshift({
      title: "Open",
      children: [
        {
          title: "File",
          alt: "GeoJSON, TopoJSON, GTFS, KML, CSV, GPX and OSM XML supported",
          action: this.blindImport,
        },
      ],
    });

    return (
      <div className="flex inline-flex w-100">
        <img
          src="https://appdevelopment.de/wp-content/uploads/2019/02/AppDev-1a.png"
          alt="Nature"
          className="responsive"
          height="60"
        ></img>
        {user.signInUserSession.accessToken.payload["cognito:groups"] &&
        user.signInUserSession.accessToken.payload["cognito:groups"].includes(
          "GOLFCLUB_ADMIN"
        ) ? (
          actions.map((item, i) => {
            return (
              <div
                key={i}
                style={{ zIndex: 999, color: "" }}
                onClick={item.action}
                className="bn pa2 outline-2 disappear-child relative pointer black hover-bg-blue self-center dn db-l"
              >
                {item.title}
                {item.children ? (
                  <div
                    hidden={false}
                    className="child bg-white absolute w4 ba pv2"
                    style={{
                      top: 24,
                      left: 0,
                    }}
                  >
                    {item.children.map((child, i) => {
                      return (
                        <div
                          onClick={child.action}
                          key={i}
                          className={`bn pv2 ph2 tl hover-bg-blue w-100 pointer`}
                        >
                          {child.title}
                        </div>
                      );
                    })}
                  </div>
                ) : null}
              </div>
            );
          })
        ) : (
          <div></div>
        )}

        <div style={{ flexGrow: 1 }}></div>

        <div
          hidden={false}
          style={{ zIndex: 999 }}
          className="db bn w5 tc outline-2 disappear-child relative f3 br3 ma1 pointer normal hover-light-blue golfCourts-dropdown-title"
        >
          <span className="self-center di ">{currCourt.name} </span>
          {allCourts.size >= 1 ? (
            <span>
              <img
                style={{ display: "inline" }}
                width={20}
                src={DropdownIcon}
                alt="GolfCourt Dropdown Selector"
              />
              <div
                className="child w5 bg-white absolute shadow-3 br3 pa2"
                style={{
                  top: 52,
                  left: 0,
                  //borderTop: "5px solid transparent",
                }}
              >
                {this.golfCourtsDropdown(allCourts)}
              </div>{" "}
            </span>
          ) : null}
        </div>

        <div style={{ flexGrow: 1 }}></div>
        <span className="self-center fw6 dn db-l">
          {user.attributes?.email}
        </span>
        <button
          className="button sign-out dn db-l"
          onClick={() => {
            Auth.signOut();
            // Window reload Added due to leaflet realoading gray tiles bug
            window.location.reload();
          }}
        >
          Sign Out
        </button>
        <button
          className="button publish fw6 dn db-l"
          onClick={() => saveGolfCourtToDB()}
          data-test="publish-button"
        >
          Publish
        </button>
        {/* <input type="checkbox" id="burger" className="db"></input>
        <label htmlFor="burger" className="db fr pr5 f2">
          <img src="https://dwyl.com/img/favicon-32x32.png" alt="dwyl logo" />
        </label>
        <ul className="menu overflow-hidden db w-100-l w-70 list pa0 ma0 mt1-l pt1 f2 f3-l black">
          <li className="fl pl5 pl6-l">
            <a href="/nav-menu.html">
              <img
                src="https://dwyl.com/img/favicon-32x32.png"
                alt="dwyl logo"
              />
            </a>
          </li>
          <li className="di-l pl6 tl pt5-m pb2-m">
            <a href="#" className="black link">
              Portfolio
            </a>
          </li>
          <li className="pl5 pl6-m di-l tl pb2-m">
            <a href="#" className="black link">
              Blog
            </a>
          </li>
          <li className="pl5 pl6-m di-l tl pb2-m">
            <a href="#contact" id="contact-link" className="black link">
              Contact
            </a>
          </li>
          <li className="pl6-m tl dn-l pb3">
            <a href="https://youtu.be/dQw4w9WgXcQ" className="black link">
              Sup?
            </a>
          </li>
        </ul> */}

        <input
          type="file"
          className="dn"
          multiple
          ref={this.fileInputRef}
          onChange={this.onFileInputChange}
        />
      </div>
    );
  }
}
