export type UserInterface = {
  cognitoUser: Record<string, unknown> | undefined;
  userAttributes: Record<string, string>;
  cognitoGroups: string[];
};

type postUserType = {
  type: "ON_POST_USER_ATTRIBUTES";
  cognitoUser: Record<string, unknown>;
  userAttributes: Record<string, string>;
};

type setUserType = {
  type: "ON_SET_USER";
  cognitoUser: Record<string, unknown>;
  userAttributes: Record<string, string>,
  cognitoGroups: string[]
};

export type UserActionsTypes =
  | setUserType
  | postUserType


// Type of actions
export const ON_GET_USER = "ON_GET_USER";
export const ON_POST_USER_ATTRIBUTES = "ON_POST_USER_ATTRIBUTES";
export const ON_SET_USER = "ON_SET_USER";

const SETUser = (
  cognitoUser: Record<string, unknown>,
  userAttributes: Record<string, string>,
  cognitoGroups: string[]
): UserActionsTypes => ({
  type: ON_SET_USER,
  cognitoUser: cognitoUser,
  userAttributes: userAttributes,
  cognitoGroups: cognitoGroups
});


const POSTUser = (
  cognitoUser: Record<string, unknown>,
  userAttributes: Record<string, string>,
): UserActionsTypes => ({
  type: ON_POST_USER_ATTRIBUTES,
  cognitoUser: cognitoUser,
  userAttributes: userAttributes,
});


export default {
  SETUser,
  POSTUser
};
