import HTTPClient from "../../utils/httpClient";
import { GolfCourtActionsTypes } from "./golfCourts.actions";
import { RootState } from "./../rootStore";
import GOLFCOURTS_ACTIONS from "./golfCourts.actions";
import { AxiosResponse } from "axios";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { GolfCourt } from "../../models";

export type ThunkResult<Result> = ThunkAction<
  Result,
  RootState,
  undefined,
  GolfCourtActionsTypes
>;

const getGolfCourt = (id: string): ThunkResult<Promise<string>> => {
  const http = new HTTPClient();
  const request = http.get<GolfCourt>("/golfcourts/" + id);

  return async (
    dispatch: ThunkDispatch<RootState, undefined, GolfCourtActionsTypes>
  ) => {
    try {
      const response = await request;
      dispatch(GOLFCOURTS_ACTIONS.GETGolfCourt(id, response));
      return "Sucessfully fetched GolfCourt";
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

const putGolfCourt = (golfCourt: GolfCourt): ThunkResult<Promise<string>> => {
  const http = new HTTPClient();
  const request = http.put<AxiosResponse<GolfCourt>>("/golfcourts?name=New%20Court", golfCourt);

  return async (
    dispatch: ThunkDispatch<RootState, undefined, GolfCourtActionsTypes>
  ) => {
    try {
      const response = await request;
      const newGolfCourt: GolfCourt = response.data
      dispatch(GOLFCOURTS_ACTIONS.PUTGolfCourt(newGolfCourt));
      return "Sucessfully Created Golfcourt";
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

const postGolfCourt = (
  id: string,
  golfCourt: GolfCourt
): ThunkResult<Promise<string>> => {
  const http = new HTTPClient();
  // Fazer query pro db com todas as músicas
  const request = http.post<AxiosResponse<string>>(
    "/golfcourts/" + id,
    golfCourt
  );

  return async (
    dispatch: ThunkDispatch<RootState, undefined, GolfCourtActionsTypes>
  ) => {
    try {
      await request;
      dispatch(GOLFCOURTS_ACTIONS.POSTGolfCourt(id, golfCourt));
      return "Sucessfully Updated Golfcourt ID: " + id;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

const deleteGolfCourt = (id: string): ThunkResult<Promise<string>> => {
  const http = new HTTPClient();
  const request = http.delete<AxiosResponse<string>>("/golfcourts/" + id);

  return async (
    dispatch: ThunkDispatch<RootState, undefined, GolfCourtActionsTypes>
  ) => {
    try {
      await request;
      dispatch(GOLFCOURTS_ACTIONS.DELETEGolfCourt(id));
      return "Sucessfully Deleted Golfcourt ID: " + id;
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

export default {
  getGolfCourt,
  putGolfCourt,
  postGolfCourt,
  deleteGolfCourt,
};
