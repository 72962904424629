import L from "leaflet";
import distanceMarkerPNG from "./../../../img/distance-marker.png";

import flagMarkerSVG from "./../../../img/flag-marker.svg";

const defaultProperties = {
  opacity: 1,
  fillOpacity: 0.5,
  strokeOpacity: 1,
  strokeWidth: 10,
};

const FlagIcon = L.Icon.extend({
  //iconRetinaUrl,
  //shadowUrl,
  options: {
    iconSize: [20, 30],
    iconAnchor: [10, 25],
    popupAnchor: [1, -34],
    //tooltipAnchor: [16, -28],
    //shadowSize: [41, 41],
  },
});

const DistanceIcon = L.Icon.extend({
  //iconRetinaUrl,
  //shadowUrl,
  options: {
    iconSize: [16, 16],
    iconAnchor: [8, 16],
    popupAnchor: [1, -22],
    //tooltipAnchor: [16, -28],
    //shadowSize: [41, 41],
  },
});

const FlagMarkerIcon = new FlagIcon({
  iconUrl: flagMarkerSVG,
});
const DistanceMarkerIcon = new DistanceIcon({
  iconUrl: distanceMarkerPNG,
});

export default {
  GREEN: {
    // Spread operator not working for when used multiple times
    // probably problem with babel spread plugin
    ...defaultProperties,
    ...{
      golf_court_annotation: "GREEN",
      color: "#BAD278",
      fill: true,
      fillColor: "#BAD278",
    },
  },
  WATER: Object.assign(
    {
      golf_court_annotation: "WATER",
      color: "#72BDF6",
      fill: true,
      fillColor: "#72BDF6",
    },
    defaultProperties
  ),
  BUNKER: Object.assign(
    {
      golf_court_annotation: "BUNKER",
      color: "#EDD9AF",
      fill: true,
      fillColor: "#EDD9AF",
    },
    defaultProperties
  ),
  FAIRWAY: Object.assign(
    {
      golf_court_annotation: "FAIRWAY",
      color: "#43A93B",
      fill: true,
      fillColor: "#43A93B",
    },
    defaultProperties
  ),
  TEE_RED: Object.assign(
    {
      golf_court_annotation: "TEE_RED",
      color: "red",
      fill: true,
      fillColor: "red",
    },
    defaultProperties
  ),
  TEE_BLUE: Object.assign(
    {
      golf_court_annotation: "TEE_BLUE",
      color: "blue",
      fill: true,
      fillColor: "blue",
    },
    defaultProperties
  ),
  TEE_YELLOW: Object.assign(
    {
      golf_court_annotation: "TEE_YELLOW",
      color: "yellow",
      fill: true,
      fillColor: "yellow",
    },
    defaultProperties
  ),
  TEE_WHITE: Object.assign(
    {
      golf_court_annotation: "TEE_WHITE",
      color: "white",
      fill: true,
      fillColor: "white",
    },
    defaultProperties
  ),
  TREE: Object.assign(
    {
      golf_court_annotation: "TREE",
      color: "#997960",
      fill: true,
      fillColor: "#997960",
    },
    defaultProperties
  ),
  ROUGH: Object.assign(
    {
      golf_court_annotation: "ROUGH",
      color: "#005130",
      fill: true,
      fillColor: "#005130",
    },
    defaultProperties
  ),
  PATH: Object.assign(
    {
      golf_court_annotation: "PATH",
      color: "#807E78",
      weight: 10,
    },
    defaultProperties
  ),
  CUSTOM: Object.assign(
    {
      golf_court_annotation: "CUSTOM",
      color: "yellow",
      fill: true,
      fillColor: "yellow",
    },
    defaultProperties
  ),
  PENALTY_RED: Object.assign(
    {
      golf_court_annotation: "PENALTY_RED",
      color: "#FF0000FF",
      weight: 5,
      dashArray: "0px, 25px",
    },
    defaultProperties
  ),
  PENALTY_YELLOW: Object.assign(
    {
      golf_court_annotation: "PENALTY_YELLOW",
      color: "#FFFF00FF",
      weight: 5,
      dashArray: "0px, 25px",
    },
    defaultProperties
  ),
  PENALTY_OUT: Object.assign(
    {
      golf_court_annotation: "PENALTY_OUT",
      color: "#FFFFFFFF",
      weight: 5,
      dashArray: "0px, 25px",
    },
    defaultProperties
  ),
  DISTANCE_POINT: Object.assign(
    {
      golf_court_annotation: "DISTANCE_POINT",
      icon: DistanceMarkerIcon,
    },
    defaultProperties
  ),
  FLAG_POSITION_A: Object.assign(
    {
      golf_court_annotation: "FLAG_POSITION_A",
      icon: FlagMarkerIcon,
      riseOnHover: false,
    },
    defaultProperties
  ),
  FLAG_POSITION_B: Object.assign(
    {
      golf_court_annotation: "FLAG_POSITION_B",
      icon: FlagMarkerIcon,
    },
    defaultProperties
  ),
  FLAG_POSITION_C: Object.assign(
    {
      golf_court_annotation: "FLAG_POSITION_C",
      icon: FlagMarkerIcon,
    },
    defaultProperties
  ),
  FLAG_POSITION_D: Object.assign(
    {
      golf_court_annotation: "FLAG_POSITION_D",
      icon: FlagMarkerIcon,
    },
    defaultProperties
  ),
  FLAG_POSITION_E: Object.assign(
    {
      golf_court_annotation: "FLAG_POSITION_E",
      icon: FlagMarkerIcon,
    },
    defaultProperties
  ),
};
