import { createStore, applyMiddleware } from "redux";
import {
  saveState,
  //loadState
} from "./../utils/localStorage";

// Logger with default options
//import logger from "redux-logger";
import thunk from "redux-thunk";

import { combineReducers } from "redux";
import golfCourtsReducer from "./golfCourts/golfCourts.reducer";
import userReducer from "./user/user.reducer";


export const rootReducer = combineReducers({
  golfCourts: golfCourtsReducer,
  user: userReducer,
});

export type RootState = ReturnType<typeof rootReducer>;

export default function configureStore(): any {
  //const persistedState = loadState();
  const store = createStore(rootReducer, {}, 
    //applyMiddleware(logger, thunk)
    applyMiddleware(thunk)
    );

  let throttleTime = new Date().getTime();

  store.subscribe(() => {
    if (new Date().getTime() - throttleTime > 1000) {
      throttleTime = new Date().getTime();
      saveState(store.getState());
    }
  });

  return store;
}
