import MapEditor from "../views/MapEditor/MapEditor";
import { connect } from "react-redux";
import { RootState } from "./../store/rootStore";
import { GolfCourt } from "./../models/index";
import { GolfCourtActionsTypes } from "./../store/golfCourts/golfCourts.actions";
import { UserActionsTypes } from "./../store/user/user.actions";
import GolfCourtsActions from "./../store/golfCourts/golfCourts.actions";
import GolfCourtsThunks from "./../store/golfCourts/golfCourts.thunks";
import UserActions from "./../store/user/user.actions";
import UserThunks from "./../store/user/user.thunks";
import { ThunkDispatch } from "redux-thunk";

export type StateProps = {
  currCourt: GolfCourt | undefined;
  allCourts: Map<string, GolfCourt>;
  user: Record<string, unknown> | undefined;
  userAttributes: Record<string, string>;
  cognitoGroups: string[];
};

export type DispatchProps = {
  getGolfCourt: (id: string) => void;
  putGolfCourt: (golfCourt: GolfCourt) => void;
  postGolfCourt: (id: string, golfCourt: GolfCourt) => void;
  setGolfCourt: (golfCourt: GolfCourt) => void;
  switchGolfCourt: (id: string) => void;
  setUser: (cognitoUser: Record<string, unknown>, userAttributes: Record<string, string>, cognitoGroups: string[]) => void;
  postUserAttributes: (cognitoUser: Record<string, unknown>, userAttributes: Record<string, string>) => void;
};

const mapStateToProps = (
  state: RootState
  //props: Record<string, unknown>
): StateProps => {
  return {
    currCourt: state.golfCourts.currCourt,
    allCourts: state.golfCourts.allCourts,
    user: state.user.cognitoUser,
    cognitoGroups: state.user.cognitoGroups,
    userAttributes: state.user.userAttributes,
  };
};

const mapDispatchToProps = (
  dispatch: ThunkDispatch<RootState, undefined, GolfCourtActionsTypes | UserActionsTypes>
): DispatchProps => ({
  getGolfCourt: (id: string): void => {
    dispatch(GolfCourtsThunks.getGolfCourt(id));
  },
  putGolfCourt: (golfCourt:  GolfCourt): void => {
    dispatch(GolfCourtsThunks.putGolfCourt(golfCourt));
  },
  postGolfCourt: (id: string, golfCourt: GolfCourt): void => {
    dispatch(GolfCourtsThunks.postGolfCourt(id, golfCourt));
  },
  setGolfCourt: (golfCourt: GolfCourt): void => {
    dispatch(GolfCourtsActions.SETCurrentGolfCourt(golfCourt));
  },
  switchGolfCourt: (id: string): void => {
    dispatch(GolfCourtsActions.SWITCHCurrentGolfCourt(id));
  },
  setUser: (cognitoUser: Record<string, unknown>, userAttributes: Record<string, string>, cognitoGroups: string[]) : void => {
    dispatch(UserActions.SETUser(cognitoUser, userAttributes, cognitoGroups));
  },
  postUserAttributes: (cognitoUser: Record<string, unknown>, userAttributes: Record<string, string>): void => {
    dispatch(UserThunks.postUserAttributes(cognitoUser, userAttributes));
  }
});

export default connect(mapStateToProps, mapDispatchToProps)(MapEditor);
