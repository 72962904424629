import axios, { AxiosInstance } from "axios";
import fetch from "isomorphic-unfetch";
import { Auth } from "aws-amplify";

export default class HTTPClient {
  private readonly domain: string;
  private readonly axiosInstance: AxiosInstance;

  constructor() {
    this.domain =
      process.env.NODE_ENV === "production"
        ? "https://appdev.gustavomorais.me"
        : "http://localhost:8080";

    this.axiosInstance = axios.create({
      baseURL: this.domain,
      timeout: 1000,
      headers: {
        "Content-type": "application/json",
      },
    });
  }

  async get<T>(url: string): Promise<T> {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    const res = await fetch(this.domain + url, {
      headers: new Headers({
        Authorization: accessToken,
      }),
    });
    return await res.json();
  }

  async delete<T>(url: string): Promise<T> {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return await this.axiosInstance.delete(url, {
      headers: {
        Authorization: accessToken,
      },
    });
  }

  async post<T>(url: string, data: {}): Promise<T> {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return await this.axiosInstance.post(url, data, {
      headers: {
        Authorization: accessToken,
      },
    });
  }
  async put<T>(url: string, data: {}, params?: {}): Promise<T> {
    const session = await Auth.currentSession();
    const accessToken = session.getAccessToken().getJwtToken();
    return await this.axiosInstance.put(url, data, {
      headers: {
        Authorization: accessToken,
      },
      //params: params,
    });
  }
}
