import { GolfCourt } from "./../../models/index";

export type GolfCourtsInterface = {
  currCourt: GolfCourt | undefined;
  allCourts: Map<string, GolfCourt>;
};

type getGolfCourtType = {
  type: "ON_GET_GOLFCOURT";
  id: string;
  golfCourt: GolfCourt;
};
type putGolfCourtType = { type: "ON_PUT_GOLFCOURT"; golfCourt: GolfCourt };
type postGolfCourtType = {
  type: "ON_POST_GOLFCOURT";
  id: string;
  golfCourt: GolfCourt;
};
type deleteGolfCourtType = { type: "ON_DELETE_GOLFCOURT"; id: string };
type setCurrentGolfCourtType = {
  type: "ON_SET_GOLFCOURT";
  golfCourt: GolfCourt;
};
type switchCurrentGolfCourtType = {
  type: "ON_SWITCH_GOLFCOURT";
  id: string;
};

export type GolfCourtActionsTypes =
  | getGolfCourtType
  | putGolfCourtType
  | postGolfCourtType
  | deleteGolfCourtType
  | switchCurrentGolfCourtType
  | setCurrentGolfCourtType;

// Type of actions
export const ON_GET_GOLFCOURT = "ON_GET_GOLFCOURT";
export const ON_PUT_GOLFCOURT = "ON_PUT_GOLFCOURT";
export const ON_POST_GOLFCOURT = "ON_POST_GOLFCOURT";
export const ON_DELETE_GOLFCOURT = "ON_DELETE_GOLFCOURT";
export const ON_SET_GOLFCOURT = "ON_SET_GOLFCOURT";
export const ON_SWITCH_GOLFCOURT = "ON_SWITCH_GOLFCOURT";

const GETGolfCourt = (
  id: string,
  golfCourt: GolfCourt
): GolfCourtActionsTypes => ({
  type: ON_GET_GOLFCOURT,
  id: id,
  golfCourt: golfCourt,
});

const PUTGolfCourt = (golfCourt: GolfCourt): GolfCourtActionsTypes => ({
  type: ON_PUT_GOLFCOURT,
  golfCourt: golfCourt,
});

const POSTGolfCourt = (
  id: string,
  golfCourt: GolfCourt
): GolfCourtActionsTypes => ({
  type: ON_POST_GOLFCOURT,
  id: id,
  golfCourt: golfCourt,
});

const DELETEGolfCourt = (id: string): GolfCourtActionsTypes => ({
  type: ON_DELETE_GOLFCOURT,
  id: id,
});

const SETCurrentGolfCourt = (golfCourt: GolfCourt): GolfCourtActionsTypes => ({
  type: ON_SET_GOLFCOURT,
  golfCourt: golfCourt,
});

const SWITCHCurrentGolfCourt = (id: string): GolfCourtActionsTypes => ({
  type: ON_SWITCH_GOLFCOURT,
  id: id,
});

export default {
  GETGolfCourt,
  PUTGolfCourt,
  POSTGolfCourt,
  DELETEGolfCourt,
  SETCurrentGolfCourt,
  SWITCHCurrentGolfCourt,
};
