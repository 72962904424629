import geojsonExtent from "geojson-extent";
import { FeatureCollection, Feature, GolfCourt } from "./../../models/index";
import {
  ON_GET_GOLFCOURT,
  ON_POST_GOLFCOURT,
  ON_PUT_GOLFCOURT,
  ON_SWITCH_GOLFCOURT,
  ON_SET_GOLFCOURT,
  GolfCourtsInterface,
  GolfCourtActionsTypes,
} from "./golfCourts.actions";

// Automatic generated mock from the SwaggerUI documentation on the PUT Endpoint
export const mockGolfCourt: GolfCourt = {
  name: "New GolfCourt",
  static_parts: {
    type: "FeatureCollection",
    bbox: [
      9.076788425445557,
      50.11572124134475,
      9.095885753631592,
      50.123673570986874,
    ],
    features: [],
  },
  tees: [],
};


const defaultState: GolfCourtsInterface = {
  currCourt: mockGolfCourt,
  allCourts: new Map(),
};

// [Question] Defining return type of a whole reducer/state aprt
export default (
  state = defaultState,
  action: GolfCourtActionsTypes
): GolfCourtsInterface => {
  const { allCourts } = state;
  switch (action.type) {
    case ON_POST_GOLFCOURT:
    case ON_GET_GOLFCOURT: {
      const { golfCourt } = action;
      const { currCourt } = state;

      golfCourt.tees.forEach((tee: FeatureCollection) => {
        tee.features.forEach((feature: Feature) => {
          if (
            feature.properties.golf_court_annotation !== "GREEN" &&
            feature.properties.golf_court_annotation !== "TEE_WHITE" &&
            feature.properties.golf_court_annotation !== "TEE_RED" &&
            feature.properties.golf_court_annotation !== "TEE_YELLOW" &&
            feature.properties.golf_court_annotation !== "TEE_BLUE"
          )
            golfCourt.static_parts.features.push(feature);
        });
      });

      const allCourtsUpdated = new Map(allCourts);
      if(currCourt?._id) allCourtsUpdated.set(currCourt._id, currCourt);

      return {
        ...state,
        currCourt: golfCourt,
        allCourts: allCourtsUpdated,
      };
    }
    // case ON_POST_GOLFCOURT: {
    //   const { golfCourt } = action;
    //   return {
    //     ...state,
    //     currCourt: golfCourt,
    //   };
    // }
    case ON_PUT_GOLFCOURT: {
      const { golfCourt } = action;
      const { currCourt } = state;
      const allCourtsUpdated = state.allCourts;

      if (currCourt && currCourt._id) {
        allCourtsUpdated.set(currCourt._id, currCourt);
      }

      return {
        ...state,
        currCourt: golfCourt,
        allCourts: allCourtsUpdated
      };
    }
    case ON_SET_GOLFCOURT: {
      const { golfCourt } = action;
      if (!golfCourt._id) return state;

      const bBoxCurrCourt: GolfCourt = { ...golfCourt, 
      static_parts: geojsonExtent.bboxify(
        golfCourt.static_parts
      ),
      tees: [],
    };

    golfCourt.tees.forEach((element, index) => {
      bBoxCurrCourt.tees[index] = geojsonExtent.bboxify(element);
    });

      // In case of just updating and not switching
      return {
        ...state,
        currCourt: golfCourt,
      };
    }
    case ON_SWITCH_GOLFCOURT: {
      const { id } = action;
      const { currCourt } = state;

      if (!id) return state;

      const currCourtUpdated = state.allCourts.get(id);
      const allCourtsUpdated = state.allCourts;

      if (currCourt && currCourt._id) {
        allCourtsUpdated.set(currCourt._id, currCourt);
        allCourtsUpdated.delete(id);
      }

      return {
        ...state,
        currCourt: currCourtUpdated,
        allCourts: allCourtsUpdated,
      };
    }
    default:
      return state;
  }
};
