import React from "react";
import ReactDOM from "react-dom";
import L from "leaflet";
import waterIconSVG from "./../../../../img/water-icon.svg";
import treeIconSVG from "./../../../../img/tree-icon.svg";
import fairwayIconSVG from "./../../../../img/fairway-icon.svg";
import roughIconSVG from "./../../../../img/rough-icon.svg";
import teeIconSVG from "./../../../../img/tee-icon.svg";
import bunkerIconSVG from "./../../../../img/bunker-icon.svg";
import pathIconSVG from "./../../../../img/path-icon.svg";
import customIconSVG from "./../../../../img/custom-icon.svg";
import greenIconSVG from "./../../../../img/green-icon.svg";
import penaltyIconSVG from "./../../../../img/penalty-icon.svg";
import distanceIconSVG from "./../../../../img/distance-icon.svg";
import flagIconSVG from "./../../../../img/flag-icon.svg";
import golfStyleLayerConfig from "../mapGolfstyles";

export function addMarkingControls(map) {
  L.NewSVGControl = L.Control.extend({
    options: {
      position: "topleft",
      callback: null,
      kind: "",
      background: "black",
    },

    onAdd: function (map) {
      var container = L.DomUtil.create(
          "div",
          "leaflet-control leaflet-bar golf-mark"
        ),
        link = L.DomUtil.create("a", "", container);

      link.id = this.options.id;
      //link.on("click", (e) => e.stopPropagation());

      ReactDOM.render(
        <div>
          <div
            id={this.options.id + "OVERLAY"}
            className={
              this.options.mapClass.teeControls.find(
                (element) => element + "CONTROL" === this.options.id
              )
                ? "disabled-control-overlay control-disabled"
                : "disabled-control-overlay"
            }
          ></div>
          <div
            className="control-internal-container"
            onClick={() => {
              window.LAYER = this.options.callback.call(map.editTools);
            }}
          >
            <img
              className="control-img-icon"
              src={this.options.svgPath}
              alt={this.options.kind + " Mark"}
            />
            <span style={{ display: "inline-block" }}>{this.options.kind}</span>
          </div>
        </div>,
        link
      );

      return container;
    },
  });

  L.NewGreenControl = L.NewSVGControl.extend({
    options: {
      id: "GREENCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["GREEN"],
          "GREENCONTROL"
        ),
      kind: "Green Area",
      svgPath: greenIconSVG,
    },
  });

  L.NewWaterControl = L.NewSVGControl.extend({
    options: {
      id: "WATERCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["WATER"],
          "WATERCONTROL"
        ),
      kind: "Water Area",
      svgPath: waterIconSVG,
    },
  });

  L.NewBunkerControl = L.NewSVGControl.extend({
    options: {
      id: "BUNKERCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["BUNKER"],
          "BUNKERCONTROL"
        ),
      kind: "Bunker Area",
      svgPath: bunkerIconSVG,
    },
  });

  L.NewFairwayControl = L.NewSVGControl.extend({
    options: {
      id: "FAIRWAYCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["FAIRWAY"],
          "FAIRWAYCONTROL"
        ),
      kind: "Fairway Area",
      svgPath: fairwayIconSVG,
    },
  });

  L.NewTeeControl = L.NewSVGControl.extend({
    options: {
      id: "TEECONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startRectangle(
          undefined,
          golfStyleLayerConfig["TEE_WHITE"],
          "TEECONTROL"
        ),
      kind: "Tee Spot",
      svgPath: teeIconSVG,
    },
  });

  L.NewTreeControl = L.NewSVGControl.extend({
    options: {
      id: "TREECONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["TREE"],
          "TREECONTROL"
        ),
      kind: "Tree Area",
      svgPath: treeIconSVG,
    },
  });

  L.NewRoughControl = L.NewSVGControl.extend({
    options: {
      id: "ROUGHCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["ROUGH"],
          "ROUGHCONTROL"
        ),
      kind: "Rough Area",
      svgPath: roughIconSVG,
    },
  });

  L.NewPathControl = L.NewSVGControl.extend({
    options: {
      id: "PATHCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolyline(
          undefined,
          golfStyleLayerConfig["PATH"],
          "PATHCONTROL"
        ),
      kind: "Path",
      svgPath: pathIconSVG,
    },
  });

  L.NewCustomControl = L.NewSVGControl.extend({
    options: {
      id: "CUSTOMCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolygon(
          undefined,
          golfStyleLayerConfig["CUSTOM"],
          "CUSTOMCONTROL"
        ),
      kind: "Custom Area",
      svgPath: customIconSVG,
    },
  });

  L.NewPenaltyControl = L.NewSVGControl.extend({
    options: {
      id: "PENALTYCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startPolyline(
          undefined,
          golfStyleLayerConfig["PENALTY_RED"],
          "PENALTYCONTROL"
        ),
      kind: "Penalty Marks",
      svgPath: penaltyIconSVG,
    },
  });

  L.NewDistancePointControl = L.NewSVGControl.extend({
    options: {
      id: "DISTANCE_POINTCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startMarker(
          undefined,
          golfStyleLayerConfig["DISTANCE_POINT"],
          "DISTANCE_POINTCONTROL"
        ),
      kind: "Distance Point",
      svgPath: distanceIconSVG,
    },
  });

  L.NewFlagPositionControl = L.NewSVGControl.extend({
    options: {
      id: "FLAG_POSITIONCONTROL",
      mapClass: this,
      position: "topleft",
      callback: () =>
        this.startMarker(
          undefined,
          golfStyleLayerConfig["FLAG_POSITION_A"],
          "FLAG_POSITIONCONTROL"
        ),
      kind: "Flag Position",
      svgPath: flagIconSVG,
    },
  });

  map.addControl(new L.NewFairwayControl());
  map.addControl(new L.NewRoughControl());
  map.addControl(new L.NewPathControl());
  map.addControl(new L.NewCustomControl());
  map.addControl(new L.NewWaterControl());
  map.addControl(new L.NewBunkerControl());
  map.addControl(new L.NewTreeControl());
  //map.addControl(new L.NewGreenControl());
  //map.addControl(new L.NewTeeControl());
  //map.addControl(new L.NewPenaltyControl());
  //map.addControl(new L.NewDistancePointControl());
  //map.addControl(new L.NewFlagPositionControl());
}
