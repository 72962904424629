import { UserActionsTypes } from "./user.actions";
import { RootState } from "../rootStore";
import USER_ACTIONS from "./user.actions";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import { Auth } from "aws-amplify";


export type ThunkResult<Result> = ThunkAction<
  Result,
  RootState,
  undefined,
  UserActionsTypes
>;

const postUserAttributes = (
  cognitoUser: Record<string, unknown>,
  userAttributes: Record<string, string>,
): ThunkResult<Promise<string>> => {

  const request = Auth.updateUserAttributes(cognitoUser, userAttributes);

  return async (
    dispatch: ThunkDispatch<RootState, undefined, UserActionsTypes>
  ) => {
    try {
      await request;
      dispatch(USER_ACTIONS.POSTUser(cognitoUser, userAttributes));
      return "Sucessfully Updated User's Attributes";
    } catch (error) {
      console.log(error);
      return error;
    }
  };
};

export default {
  postUserAttributes
};
