// Automatic generated mock from the SwaggerUI documentation on the PUT Endpoint
export const mockGolfCourt = {
  _id: "string",
  name: "New GolfCourt",
  static_parts: {
    type: "FeatureCollection",
    bbox: [
      9.076788425445557,
      50.11572124134475,
      9.095885753631592,
      50.123673570986874,
    ],
    features: [],
  },
  tees: [],
};
