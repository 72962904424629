import React from "react";
import { Route, Switch, BrowserRouter as Router } from "react-router-dom";
import { Provider } from "react-redux";
import configureStore from "./store/rootStore";
import MapEditor from "./containers/MapEditorContainer";
//import MapEditor from "./views/MapEditor/MapEditor";

const store = configureStore();

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

const App: React.FC = () => {
  return (
    <Provider store={store}>
      <Router>
        <React.Suspense fallback={loading()}>
          <Switch>
            <Route exact path="/" render={() => <MapEditor />} />
            <Route path="/default" render={() => <div>Default page</div>} />
          </Switch>
        </React.Suspense>
      </Router>
    </Provider>
  );
};

export default App;
