import React from "react";
import { AmplifyAuthenticator, AmplifySignIn } from "@aws-amplify/ui-react";
import "./sign_in.css";
import GolfappLogo from "./../../img/golftackle-logo.svg";

export default class SignIn extends React.Component {
  render() {
    return (
      <div className="signin-container flex flex-column flex-row-l items-center justify-center content-center">
        <div className="img-container flex w-50 h-25 pa4 justify-center items-end w-50-l h-100-l justify-end-l items-center-l pa7-l pr0-l">
          <img
            className="flex w-100"
            alt="Golftackle Logo"
            src={GolfappLogo}
          ></img>
        </div>
        <AmplifyAuthenticator className="flex w-50 justify-center h-50 w-50-l items-start h-100-l justify-start-l items-center-l">
          <AmplifySignIn
            headerText="Welcome, sign in to your account"
            slot="sign-in"
            //hideSignUp
          ></AmplifySignIn>
        </AmplifyAuthenticator>
      </div>
    );
  }
}
