import React from "react";

export default class Popup extends React.Component {
  render() {
    const {
      layer,
      editProperties,
      popupRemoveLayer,
      reloadFeaturesLayer,
    } = this.props;
    const { properties } = layer.toGeoJSON();
    return (
      <div className="flex fw6">
        <span
          style={{
            backgroundColor: "white",
            width: 30,
            borderRight: "1px solid black",
          }}
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => {
            // Editing TEE Properties
            editProperties({
              updated_src: Object.assign(properties, {
                golf_court_annotation: "TEE_WHITE",
              }),
            });
            reloadFeaturesLayer();
          }}
        ></span>
        <span
          style={{
            backgroundColor: "yellow",
            width: 30,
          }}
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => {
            // Editing TEE Properties
            editProperties({
              updated_src: Object.assign(properties, {
                golf_court_annotation: "TEE_YELLOW",
              }),
            });
            reloadFeaturesLayer();
          }}
        ></span>
        <span
          style={{
            backgroundColor: "blue",
            width: 30,
          }}
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => {
            // Editing TEE Properties
            editProperties({
              updated_src: Object.assign(properties, {
                golf_court_annotation: "TEE_BLUE",
              }),
            });
            reloadFeaturesLayer();
          }}
        ></span>
        <span
          style={{
            backgroundColor: "red",
            width: 30,
            borderRight: "1px solid black",
          }}
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => {
            // Editing TEE Properties
            editProperties({
              updated_src: Object.assign(properties, {
                golf_court_annotation: "TEE_RED",
              }),
            });
            reloadFeaturesLayer();
          }}
        ></span>
        <span
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => popupRemoveLayer(layer)}
          data-test="delete-feature"
        >
          Delete
        </span>
      </div>
    );
  }
}
