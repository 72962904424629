import React from "react";
import "./golf_popups.css";

export default class Popup extends React.Component {
  state = {
    selectedFlag: "",
  };

  flagPositions = [
    "FLAG_POSITION_A",
    "FLAG_POSITION_B",
    "FLAG_POSITION_C",
    "FLAG_POSITION_D",
    "FLAG_POSITION_E",
  ];
  componentDidMount() {
    const { layer } = this.props;
    const { properties } = layer.toGeoJSON();

    this.setState({ selectedFlag: properties.golf_court_annotation });
  }
  render() {
    const {
      layer,
      editProperties,
      popupRemoveLayer,
      reloadFeaturesLayer,
    } = this.props;
    const { properties } = layer.toGeoJSON();

    return (
      <div className="flex fw6">
        {this.flagPositions.map((element) => (
          <span
            key={element}
            id={element}
            style={{
              width: 30,
              fontSize: 16,
              borderRight: "1px solid black",
            }}
            className={
              properties.golf_court_annotation === element
                ? "db tc pointer bn pa2 hover-bg-light-blue selected_flag"
                : "db tc pointer bn pa2 hover-bg-light-blue"
            }
            onClick={() => {
              document
                .getElementById(this.state.selectedFlag)
                .classList.remove("selected_flag");
              document.getElementById(element).classList.add("selected_flag");

              this.setState({ selectedFlag: element });
              // Editing TEE Properties
              editProperties({
                updated_src: Object.assign(properties, {
                  golf_court_annotation: element,
                }),
              });
              reloadFeaturesLayer();
            }}
          >
            {element.replace("FLAG_POSITION_", "")}
          </span>
        ))}

        <span
          className="db pointer bn pa2 hover-bg-light-blue"
          onClick={() => popupRemoveLayer(layer)}
          data-test="delete-feature"
        >
          Delete
        </span>
      </div>
    );
  }
}
