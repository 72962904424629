import React, { useState } from "react";
import {
  ProSidebar,
  Menu,
  MenuItem,
  SubMenu,
  SidebarHeader,
  SidebarFooter,
} from "./../../components/sidebar";
import teeIconSVG from "./../../../img/tee-icon.svg";
import greenIconSVG from "./../../../img/green-icon.svg";
import penaltyIconSVG from "./../../../img/penalty-icon.svg";
import distanceIconSVG from "./../../../img/distance-icon.svg";
import flagIconSVG from "./../../../img/flag-icon.svg";
import { FeatureCollection, GolfCourt } from "./../../../models/index";
import "./MapSidebar.scss";
import "./../../components/sidebar/scss/styles.scss";

import {
  StateProps,
  DispatchProps,
} from "../../../containers/MapSidebarContainer";

type mapDirectProps = {
  setCurrCourt: (golfCourt: GolfCourt) => void;
  teeControls: string[];
  teeClicked: <T>(id: T) => void;
  teeControlShiftUpDown: (teeIndex: number, shiftDirection: string) => void;
  teeControlsOnClick: Record<string, () => void>;
  onHoverFeatureHighlight: (bBox: Array<number>) => L.Polygon;
  onHoverLeaveFeatureHighlight: (feature: L.Polygon) => void;
};
const MapSidebar: React.FC<StateProps & DispatchProps & mapDirectProps> = ({
  currCourt,
  setCurrCourt,
  setGolfCourt,
  teeControls,
  teeClicked,
  teeControlsOnClick,
  onHoverFeatureHighlight,
  onHoverLeaveFeatureHighlight,
}) => {
  const [sidebarCollapsed, setSidebarCollapsed] = useState(false);
  const [teesSubmenusCollapsed, setTeesSubmenusCollapsed] = useState<boolean[]>(
    []
  );
  const [draggedTeeIndex, setDraggedTeeIndex] = useState(-1);

  const formatSVGIcon = (iconType: string) => {
    let iconSVG = flagIconSVG;
    switch (iconType) {
      case "Tee":
        iconSVG = teeIconSVG;
        break;
      case "Flag Position":
        iconSVG = flagIconSVG;
        break;
      case "Green":
        iconSVG = greenIconSVG;
        break;
      case "Penalty":
        iconSVG = penaltyIconSVG;
        break;
      case "Distance Point":
        iconSVG = distanceIconSVG;
        break;
      default:
        break;
    }
    return <img src={iconSVG} alt={`${iconType} Icon`} />;
  };

  const formatTextIcon = (text: string) => {
    return <span className="text-icon">{text}</span>;
  };

  const teeAdd = () => {
    if (currCourt === undefined) return;
    const emptyFeatureCollection: FeatureCollection = {
      bbox: [],
      type: "FeatureCollection",
      features: [],
    };

    currCourt.tees.push(emptyFeatureCollection);
    setCurrCourt(currCourt);
    teeSubmenuClicked(currCourt.tees.length - 1);
  };

  const teeSubmenuClicked = (featureTee: number) => {
    const newTeesSubmenusCollapsed = teesSubmenusCollapsed.map(() => false);
    newTeesSubmenusCollapsed[featureTee] = !teesSubmenusCollapsed[featureTee];

    setTeesSubmenusCollapsed(newTeesSubmenusCollapsed);
    teeClicked(featureTee);
  };
  const onTeeDragStart = (e: React.DragEvent<HTMLDivElement>): void => {
    const draggedTeeIndex = Number(e.currentTarget.getAttribute("id"));
    setDraggedTeeIndex(draggedTeeIndex);
  };

  const onTeeDrop = (e: React.DragEvent<HTMLDivElement>): void => {
    const droppedTeeIndex = Number(e.currentTarget.getAttribute("id"));
    teePositionShift(draggedTeeIndex, droppedTeeIndex);
  };

  const teePositionShift = (teeCurrentIndex: number, teeNewIndex: number) => {
    const updatedGolfCourt = currCourt;
    if (
      updatedGolfCourt === undefined ||
      teeCurrentIndex < 0 ||
      teeCurrentIndex >= updatedGolfCourt.tees.length ||
      teeNewIndex < 0 ||
      teeNewIndex >= updatedGolfCourt.tees.length
    ) {
      return;
    }

    const lowerTee = Math.min(teeCurrentIndex, teeNewIndex);
    const upperTee = Math.max(teeCurrentIndex, teeNewIndex);
    const teeDiffShift = teeNewIndex > teeCurrentIndex ? -1 : 1;

    const staticFeatures = updatedGolfCourt.static_parts.features;
    for (let i = 0; i < staticFeatures.length; i++) {
      const currTee = staticFeatures[i].properties.tee;
      if (currTee !== undefined) {
        if (currTee === teeCurrentIndex) {
          staticFeatures[i].properties.tee = teeNewIndex;
        } else if (currTee >= lowerTee && currTee <= upperTee) {
          staticFeatures[i].properties.tee = currTee + teeDiffShift;
        }
      }
    }
    setCurrCourt(updatedGolfCourt);
    teeSubmenuClicked(teeNewIndex);
  };
  const teeDelete = (teeIndex: number) => {
    if (currCourt === undefined) return;
    const updatedGolfCourt = JSON.parse(JSON.stringify(currCourt));
    //const updatedGolfCourt = JSON.parse(JSON.stringify(golfCourt));
    updatedGolfCourt.tees.splice(teeIndex, 1);

    // Aux necessary due to splice reducing the size of the array
    let deletedIndexAux = 0;
    const staticFeatures = updatedGolfCourt.static_parts.features;
    const staticFeaturesLength = staticFeatures.length;
    for (let index = 0; index < staticFeaturesLength; index++) {
      const auxIndex = index - deletedIndexAux;
      // Verify if currTee maintains ref
      const currTee = staticFeatures[auxIndex].properties.tee;
      if (currTee !== undefined) {
        if (currTee === teeIndex) {
          updatedGolfCourt.static_parts.features.splice(auxIndex, 1);
          deletedIndexAux++;
        }
        if (currTee > teeIndex) {
          staticFeatures[auxIndex].properties.tee--;
        }
      }
    }
    setCurrCourt(updatedGolfCourt);
  };

  const initializeTeesSubmenus = (compiledSubSubmenus: {
    [key: string]: JSX.Element;
  }): { [key: string]: JSX.Element } => {
    const compiledTeeSubmenus: { [key: string]: JSX.Element } = {};

    currCourt?.tees.forEach((tee, index) => {
      compiledTeeSubmenus[index] = (
        <SubMenu
          key={"Tee" + index}
          id={index.toString()}
          className="sidebar-bg-color"
          suffix={
            teesSubmenusCollapsed[index] ? (
              <span
                className="delete-tee"
                role="img"
                aria-label="delete tee icon button"
                onClick={() => {
                  teeDelete(index);
                }}
              >
                🗑️
              </span>
            ) : null
          }
          open={
            teesSubmenusCollapsed[index] ? teesSubmenusCollapsed[index] : false
          }
          icon={formatTextIcon((index + 1).toString())}
          title={`Tee ${index + 1}`}
          onClick={() => teeSubmenuClicked(index)}
          onDragStart={onTeeDragStart}
          onDrop={onTeeDrop}
        >
          {Object.keys(compiledSubSubmenus).map((key) => {
            return compiledSubSubmenus[key];
          })}
        </SubMenu>
      );
    });

    return compiledTeeSubmenus;
  };

  const initializeMarksSubmenus = (): { [key: string]: JSX.Element } => {
    const compiledSubSubmenus: { [key: string]: JSX.Element } = {};
    teeControls.forEach((teeControl) => {
      const formattedGolfType = teeControl
        .split(/_/g)
        .map(
          (word) =>
            `${word.substring(0, 1).toUpperCase()}${word
              .substring(1)
              .toLowerCase()}`
        )
        .join(" ");
      compiledSubSubmenus[formattedGolfType] = (
        <SubMenu
          key={teeControl}
          suffix={<span className="badge yellow-background">0</span>}
          icon={formatSVGIcon(formattedGolfType)}
          title={formattedGolfType}
        >
          <span
            key={teeControl + "_ADD_BUTTON"}
            tabIndex={0}
            className={
              sidebarCollapsed ? "add-feature collapsed" : "add-feature"
            }
            onClick={teeControlsOnClick[teeControl]}
          >
            {" "}
            + Add {formattedGolfType}{" "}
          </span>
        </SubMenu>
      );
    });

    return compiledSubSubmenus;
  };

  const compileSubmenus = function () {
    const sortedFeatures = currCourt
      ? JSON.parse(
          JSON.stringify(
            currCourt.static_parts.features.sort(
              (featureA, featureB): number => {
                const golfAnnotationA =
                  featureA.properties.golf_court_annotation;
                const golfAnnotationB =
                  featureB.properties.golf_court_annotation;
                const teeA =
                  featureA.properties.tee !== undefined
                    ? featureA.properties.tee
                    : 9999;
                const teeB =
                  featureB.properties.tee !== undefined
                    ? featureB.properties.tee
                    : 9999;
                if (teeA < teeB) return -1;
                if (teeA > teeB) return 1;
                if (golfAnnotationA > golfAnnotationB) return 1;
                if (golfAnnotationA < golfAnnotationB) return -1;
                return 0;
              }
            )
          )
        )
      : [];

    if (
      sortedFeatures.length === 0 ||
      sortedFeatures[sortedFeatures.length - 1].properties.tee !== undefined
    ) {
      sortedFeatures.push({ properties: { golf_court_annotation: "WATER" } });
    }

    const compiledSubmenus = initializeTeesSubmenus(initializeMarksSubmenus());

    if (
      sortedFeatures === undefined ||
      currCourt === undefined ||
      sortedFeatures.length === 0 ||
      sortedFeatures[0].properties.golf_court_annotation === undefined ||
      Object.keys(compiledSubmenus).length === 0
    )
      return <></>;

    let compiledMenuItems: JSX.Element[] = [];

    const compiledSubSubmenus = Array.from(
      { length: currCourt.tees.length },
      initializeMarksSubmenus
    );

    let i = 0;
    let auxLastTee = 0;
    let auxLastGolfType = teeControls.find((golfType) =>
      sortedFeatures[0].properties.golf_court_annotation.includes(golfType)
    );
    if (sortedFeatures === undefined) return null;

    while (i < sortedFeatures.length) {
      const featureTee = sortedFeatures[i].properties.tee;
      const featureAnnotation =
        sortedFeatures[i].properties.golf_court_annotation;
      const featurebBox = sortedFeatures[i].bbox;
      const featureGolfType =
        featureTee !== undefined
          ? teeControls.find((golfType) => featureAnnotation.includes(golfType))
          : "Custom Mark";
      const lastTee = auxLastTee;
      const lastGolfType = auxLastGolfType;

      let auxHighlightFeature: L.Polygon;

      if (
        lastGolfType !== undefined &&
        ((featureGolfType !== lastGolfType && featureGolfType !== undefined) ||
          featureTee !== lastTee)
      ) {
        const formattedGolfType = lastGolfType
          .split(/_/g)
          .map(
            (word) =>
              `${word.substring(0, 1).toUpperCase()}${word
                .substring(1)
                .toLowerCase()}`
          )
          .join(" ");

        compiledMenuItems.push(
          <span
            key={lastGolfType + "_ADD_BUTTON"}
            tabIndex={0}
            className={
              sidebarCollapsed ? "add-feature collapsed" : "add-feature"
            }
            onClick={teeControlsOnClick[lastGolfType]}
          >
            {" "}
            + Add {formattedGolfType}{" "}
          </span>
        );
        compiledSubSubmenus[lastTee][formattedGolfType] = (
          <SubMenu
            key={lastGolfType}
            suffix={
              <span className="badge yellow-background">
                {compiledMenuItems.length - 1}
              </span>
            }
            icon={formatSVGIcon(formattedGolfType)}
            title={formattedGolfType}
          >
            {compiledMenuItems}{" "}
          </SubMenu>
        );

        compiledMenuItems = [];
        auxLastGolfType = featureGolfType;
      }

      if (
        featureTee !== lastTee ||
        featureTee === undefined ||
        lastGolfType === undefined
      ) {
        compiledSubmenus[lastTee] = (
          <SubMenu
            key={"Tee" + lastTee}
            id={lastTee.toString()}
            className="sidebar-bg-color"
            icon={formatTextIcon((lastTee + 1).toString())}
            suffix={
              teesSubmenusCollapsed[lastTee] ? (
                <span
                  className="delete-tee"
                  role="img"
                  aria-label="delete tee icon button"
                  onClick={() => {
                    teeDelete(lastTee);
                  }}
                >
                  🗑️
                </span>
              ) : null
            }
            title={`Tee ${lastTee + 1}`}
            open={
              teesSubmenusCollapsed[lastTee]
                ? teesSubmenusCollapsed[lastTee]
                : false
            }
            onClick={() => teeSubmenuClicked(lastTee)}
            onDragStart={onTeeDragStart}
            onDrop={onTeeDrop}
          >
            {Object.keys(compiledSubSubmenus[lastTee]).map((key) => {
              return compiledSubSubmenus[lastTee][key];
            })}
          </SubMenu>
        );
        if (featureTee === undefined) i = sortedFeatures.length;
        auxLastTee = featureTee !== undefined ? featureTee : lastTee + 999;
      }

      const formattedFeatureAnnotation = featureAnnotation
        ?.split(/_/g)
        .map(
          (word: string) =>
            `${word.substring(0, 1).toUpperCase()}${word
              .substring(1)
              .toLowerCase()}`
        )
        .join(" ");

      compiledMenuItems.push(
        <MenuItem
          key={formattedFeatureAnnotation + i}
          onMouseEnter={() => {
            auxHighlightFeature = onHoverFeatureHighlight(featurebBox);
          }}
          onMouseLeave={() => onHoverLeaveFeatureHighlight(auxHighlightFeature)}
        >
          {" "}
          {formattedFeatureAnnotation}{" "}
        </MenuItem>
      );
      i++;
    }

    const jsxFormattedSubmenus = Object.keys(compiledSubmenus).map((key) => {
      return compiledSubmenus[key];
    });
    return jsxFormattedSubmenus;
  };

  return (
    <ProSidebar
      collapsed={sidebarCollapsed}
      rtl={false}
      toggled={false}
      onToggle={setSidebarCollapsed}
    >
      <span
        className="collapse-arrow-wrapper"
        onClick={() => setSidebarCollapsed(!sidebarCollapsed)}
      >
        <span
          className={
            sidebarCollapsed ? "collapse-arrow-clicked" : "collapse-arrow "
          }
        />
      </span>

      <SidebarHeader>
        <div
          style={{
            padding: "24px",
            fontSize: 14,
            textAlign: "center",
            letterSpacing: "1px",
            overflow: "hidden",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
          }}
        >
          Tees Managment
        </div>
      </SidebarHeader>

      <Menu iconShape="circle">{compileSubmenus()}</Menu>
      <SidebarFooter>
        <Menu iconShape="circle">
          <MenuItem icon={formatTextIcon("+")} onClick={teeAdd}>
            <span className="add-new-tee">Add new Tee</span>
          </MenuItem>
        </Menu>
      </SidebarFooter>
    </ProSidebar>
  );
};

export default MapSidebar;
