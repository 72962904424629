import React from "react";
import LayerSwitch from "../components/map/map_layer_switch";
import NavBar from "../components/navbar/navbar";
import ModeButtons from "../components/console-panel/console_tabs";
import Map from "../components/map/map";
import Panel from "../components/console-panel/index";
import geojsonExtent from "geojson-extent";
import magicFile from "../../utils/magic_file";
import mergeGeojson from "../../utils/merge_geojson";
import { layers } from "../../utils/layers";
import SignIn from "../auth/sign_in";
import { AuthState, onAuthUIStateChange } from "@aws-amplify/ui-components";

import Amplify from "aws-amplify";
import booleanContains from "@turf/boolean-contains";

// Importing Services
import { mockGolfCourt } from "./../../services/GolfCourtService/GolfCourtMock";
import "./MapEditor.scss";

Amplify.configure({
  Auth: {
    mandatorySignIn: true,
    region: "eu-central-1",
    // private acc - userPoolId: "eu-central-1_j41uxcBf3",
    userPoolId: "eu-central-1_eAJotrjC8",
    // private acc - userPoolWebClientId: "5qmaq56mftsvqe7sijahe40jhp",
    userPoolWebClientId: "6olkb3bdva340r2usftm87ejnj",
  },
});

const initialGeojson = { type: "FeatureCollection", features: [] };

class MapEditor extends React.Component {
  state = {
    mode: "code",
    layer: "satellite",
    layers: layers,
    geojson: initialGeojson,
    changeFrom: undefined,
    dropzoneActive: false,
    showPanel: false,
    loading: true,
    authState: {},
    user: null,
  };

  teeControls = ["GREEN", "TEE", "DISTANCE_POINT", "FLAG_POSITION", "PENALTY"];

  togglePanel = () => {
    this.setState(({ showPanel }) => ({
      showPanel: !showPanel,
    }));
  };
  setMode = (mode) => {
    this.setState({ mode });
  };
  setCurrCourt = (golfCourt) => {
    const { setGolfCourt } = this.props;
    const bBoxCurrCourt = { ...golfCourt };
    bBoxCurrCourt.static_parts = geojsonExtent.bboxify(
      bBoxCurrCourt.static_parts
    );
    bBoxCurrCourt.tees.forEach((element, index) => {
      bBoxCurrCourt.tees[index] = geojsonExtent.bboxify(element);
    });

    this.setState({
      geojson: bBoxCurrCourt.static_parts,
      changeFrom: "setCurrCourt",
    });

    setGolfCourt(bBoxCurrCourt);
  };
  findFeaturesWithinPolygon = (featureCollection, polygon) => {
    const featuresWithinPolygon = [];
    featureCollection.features.forEach((feature) => {
      if (booleanContains(polygon, feature)) {
        featuresWithinPolygon.push(feature);
      }
    });

    //featuresWithinPolygon[0].properties["golf_court_annotation"] = "COURSE";
    const newTeeCollection = {
      type: "FeatureCollection",
      features: featuresWithinPolygon,
    };

    return newTeeCollection;
  };
  populateTeesFromStatic = (golfCourt) => {
    const updatedGolfCourt = { ...golfCourt };

    // Formatting
    updatedGolfCourt.tees.forEach((tee, index) => {
      updatedGolfCourt.tees[index].features = [];
    });

    updatedGolfCourt.static_parts.features.forEach((feature) => {
      const featureTee = feature.properties.tee;
      if (featureTee !== undefined) {
        // Remove from static_parts as well
        // const dynamic_parts = ["PENALTY_RED", "PENALTY_YELLOW", "PENALTY_OUT", "DISTANCE_POINT", "FLAG_POSITION"]
        // if feature.properties.golf_court_annotation in dynamic_parts {
        // bBoxCurrCourt.static_parts.features.splice(index, 1); }
        //delete feature.properties.tee;
        if (updatedGolfCourt.tees[featureTee] === undefined) {
          updatedGolfCourt.tees[featureTee] = {
            type: "FeatureCollection",
            features: [],
          };
        }

        updatedGolfCourt.tees[featureTee].features.push(feature);
      }
    });

    updatedGolfCourt.tees.forEach((tee, index) => {
      updatedGolfCourt.tees[index] = geojsonExtent.bboxify(
        updatedGolfCourt.tees[index]
      );
    });

    return updatedGolfCourt;
  };
  saveGolfCourtToDB = () => {
    const { currCourt, postGolfCourt } = this.props;
    this.setState({ loading: true });
    let bBoxCurrCourt = JSON.parse(JSON.stringify(currCourt));
    bBoxCurrCourt = this.populateTeesFromStatic(bBoxCurrCourt);

    bBoxCurrCourt.static_parts.features.forEach((feature, index) => {
      const featureTee = feature.properties.tee;
      if (featureTee !== undefined) {
        const dynamic_parts = ["PENALTY", "DISTANCE_POINT", "FLAG_POSITION"];

        for (let i = 0; i < dynamic_parts.length; i++) {
          if (
            feature.properties.golf_court_annotation.includes(dynamic_parts[i])
          ) {
            //updatedGolfCourt.static_parts.features.splice(i, 1);
            delete bBoxCurrCourt.static_parts.features[index];
            break;
          }
        }
      }
    });
    debugger;
    bBoxCurrCourt.static_parts = geojsonExtent.bboxify(
      bBoxCurrCourt.static_parts
    );
    postGolfCourt(currCourt._id, bBoxCurrCourt);
    this.setState({ loading: false });
  };
  switchGolfCourt = (golfCourtID) => {
    const { allCourts, currCourt } = this.props;
    const { switchGolfCourt } = this.props;
    let newCurrGolfCourt = {};
    let updatedAllCourts = { ...allCourts };
    Object.keys(allCourts).forEach((auxGolfCourtID) => {
      if (auxGolfCourtID === golfCourtID) {
        newCurrGolfCourt = allCourts[auxGolfCourtID];
        delete updatedAllCourts[auxGolfCourtID];
      }
    });

    const auxGolfCourt = {};
    auxGolfCourt[currCourt._id] = currCourt;
    updatedAllCourts = { [currCourt._id]: currCourt, ...updatedAllCourts };

    switchGolfCourt(golfCourtID);
    this.setState({
      geojson: newCurrGolfCourt.static_parts,
      changeFrom: "switch_court",
    });
  };
  setAuthState = (authState) => {
    this.setState({ authState });
  };

  addLayer = (event) => {
    event.preventDefault();

    const data = new FormData(event.target);

    const id = data.get("id");
    const title = data.get("title");
    const url = data.get("url");
    const attribution = data.get("attribution");

    const newLayer = {
      id,
      title,
      layer: window.L.tileLayer(url, {
        attribution,
      }),
    };
    const newLayers = this.state.layers.concat(newLayer);
    this.setState({
      layer: id,
      layers: newLayers,
    });
  };
  setLayer = (layer) => {
    this.setState({ layer });
  };
  setGeojson = (geojson, changeFrom) => {
    const { currCourt } = this.props;
    const { setGolfCourt } = this.props;

    geojson = geojsonExtent.bboxify(geojson);

    this.setState({ geojson, changeFrom });

    if (currCourt) {
      const bBoxCurrCourt = JSON.parse(JSON.stringify(currCourt));
      bBoxCurrCourt.static_parts = geojson;

      bBoxCurrCourt.tees.forEach((element, index) => {
        bBoxCurrCourt.tees[index] = geojsonExtent.bboxify(element);
      });

      setGolfCourt(bBoxCurrCourt);
    }
  };
  onDragEnter = () => {
    this.setState({
      dropzoneActive: true,
    });
  };
  onDragLeave = () => {
    this.setState({
      dropzoneActive: false,
    });
  };
  onDrop = (files) => {
    this.setState({
      dropzoneActive: false,
    });
    this.importFiles(files);
  };
  importFiles = async (files) => {
    const { geojson } = this.state;
    const { setGeojson } = this;
    const geojsons = await Promise.all(
      files.map((file) => {
        return new Promise((resolve) => {
          const reader = new FileReader();
          reader.readAsText(file);
          reader.addEventListener("load", () =>
            resolve(magicFile(reader.result))
          );
        });
      })
    );
    setGeojson(mergeGeojson([geojson, ...geojsons]));
  };

  componentDidMount() {
    // Setting function to fire with Auth Change
    onAuthUIStateChange((nextAuthState, cognitoUser) => {
      const { putGolfCourt, setUser } = this.props;
      this.setAuthState(nextAuthState);

      if (cognitoUser && nextAuthState === "signedin") {
        const userAttributes = cognitoUser.attributes;
        const cognitoGroups =
          cognitoUser.signInUserSession.accessToken.payload["cognito:groups"];
        setUser(cognitoUser, userAttributes, cognitoGroups);

        if (!userAttributes["custom:golfCourtsDBIDs"]) {
          console.log(
            "New User: No GolfCourt associated with the User. Creating New GolfCourt."
          );
          putGolfCourt(mockGolfCourt);
        }
      }
    });
  }
  componentDidUpdate(prevProps) {
    const {
      currCourt,
      getGolfCourt,
      userAttributes,
      user,
      postUserAttributes,
    } = this.props;
    const userGolfCourts = userAttributes["custom:golfCourtsDBIDs"]
      ? JSON.parse(userAttributes["custom:golfCourtsDBIDs"])
      : [];

    if (currCourt._id !== prevProps.currCourt._id) {
      this.setState({
        geojson: currCourt.static_parts,
        changeFrom: "init",
        loading: false,
      });
    }

    if (currCourt._id && !userGolfCourts.includes(currCourt._id)) {
      userGolfCourts.push(currCourt._id);
      userAttributes["custom:golfCourtsDBIDs"] = JSON.stringify(userGolfCourts);
      postUserAttributes(user, userAttributes);
    }

    if (
      userAttributes["custom:golfCourtsDBIDs"] &&
      Object.keys(prevProps.userAttributes).length === 0
    ) {
      console.log("User GolfCourts: " + userGolfCourts);
      userGolfCourts.forEach((golfCourtID) => {
        getGolfCourt(golfCourtID);
      });
    }
  }

  render() {
    const {
      geojson,
      geojsonObject,
      changeFrom,
      layer,
      layers,
      mode,
      dropzoneActive,
      showPanel,
      loading,
    } = this.state;
    const { currCourt, allCourts, user } = this.props;
    const {
      setGeojson,
      setCurrCourt,
      saveGolfCourtToDB,
      populateTeesFromStatic,
      switchGolfCourt,
      setLayer,
      setMode,
      togglePanel,
    } = this;
    return (
      // Checking if user logged
      this.state.authState === AuthState.SignedIn && user ? (
        <div>
          {loading ? (
            <div
              className="loader-container flex items-center justify-center"
              data-test="loading-spinner"
            >
              <div className="loader-lds-dual-ring"></div>
            </div>
          ) : null}
          <div className="f6 sans-serif fw6">
            <div className="vh-100 flex">
              <div
                className={`w-${showPanel ? "60" : "100"} flex flex-column z-0`}
              >
                <div className="bg-white flex justify-between bb">
                  {allCourts ? (
                    <NavBar
                      geojson={geojson}
                      geojsonObject={geojsonObject}
                      user={user}
                      setGeojson={setGeojson}
                      saveGolfCourtToDB={saveGolfCourtToDB}
                      switchGolfCourt={switchGolfCourt}
                      currCourt={currCourt}
                      allCourts={allCourts}
                      toggleConfigModal={this.toggleConfigModal}
                    />
                  ) : (
                    <div>NavBar Loading...</div>
                  )}
                </div>

                {currCourt && Object.keys(currCourt).length !== 0 ? (
                  <Map
                    layer={layer}
                    layers={layers}
                    golfCourt={currCourt}
                    setCurrCourt={setCurrCourt}
                    populateTeesFromStatic={populateTeesFromStatic}
                    geojson={geojson}
                    setGeojson={setGeojson}
                    changeFrom={changeFrom}
                    showPanel={showPanel}
                    setLayer={setLayer}
                  />
                ) : null}
                <div className="flex justify-between bt">
                  <LayerSwitch
                    layers={layers}
                    layer={layer}
                    setLayer={setLayer}
                  />
                  {user.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ] &&
                  user.signInUserSession.accessToken.payload[
                    "cognito:groups"
                  ].includes("APPDEV_ADMIN") ? (
                    <span onClick={togglePanel}>{showPanel ? "▷" : "◁"}</span>
                  ) : null}
                </div>
              </div>
              {showPanel && (
                <div className="w-40 bl flex flex-column">
                  <div
                    className="bg-white flex justify-between bb"
                    style={{
                      flexShrink: 0,
                    }}
                  >
                    <ModeButtons mode={mode} setMode={setMode} />
                  </div>
                  <Panel
                    mode={mode}
                    geojson={geojson}
                    setGeojson={setGeojson}
                    changeFrom={changeFrom}
                  />
                </div>
              )}
            </div>
          </div>
          {dropzoneActive && (
            <div
              className="absolute absolute--fill bg-black-20 flex items-center justify-center"
              style={{
                zIndex: 999,
              }}
            >
              <div className="sans-serif bg-white pa2 ba">
                Drop files to import
              </div>
            </div>
          )}
        </div>
      ) : (
        <SignIn></SignIn>
      )
    );
  }
}

export default MapEditor;
