import L, { LatLngExpression } from "leaflet";

function metersBetweenLatLong(lat1: number, lon1: number, lat2: number, lon2: number){  // generally used geo measurement function
  const R = 6378.137; // Radius of earth in KM
  const dLat = lat2 * Math.PI / 180 - lat1 * Math.PI / 180;
  const dLon = lon2 * Math.PI / 180 - lon1 * Math.PI / 180;
  const a = Math.sin(dLat/2) * Math.sin(dLat/2) +
  Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) *
  Math.sin(dLon/2) * Math.sin(dLon/2);
  const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
  const d = R * c;
  return d * 1000; // meters
}

export const createPolygonHighlight = (bBox: Array<number>, map: L.Map): L.Polygon => {
  const bBoxOffset = 0.00015;
  // Make function out of that
  const bBoxPolygon: LatLngExpression[] = [
    [bBox[1] - bBoxOffset, bBox[0] - bBoxOffset],
    [bBox[1] - bBoxOffset, bBox[2] + bBoxOffset],
    [bBox[3] + bBoxOffset, bBox[2] + bBoxOffset],
    [bBox[3] + bBoxOffset, bBox[0] - bBoxOffset],
  ];

  const newSelectedGolfTeeOuterbBox = L.polygon(bBoxPolygon, {
    color: "white",
    fillColor: "white",
    fillOpacity: 0.1,
  }).addTo(map);

  newSelectedGolfTeeOuterbBox.bringToBack();
  
  return newSelectedGolfTeeOuterbBox;
}

export const createCircleHighlight = (bBox: Array<number>, map: L.Map): L.Circle => {
  const bBoxPolygon: LatLngExpression = [
    (bBox[1] + bBox[3]) /2, (bBox[0] + bBox[2]) /2
  ]

  const radius = metersBetweenLatLong(bBox[1], bBox[0], bBox[3], bBox[2])/2;

  const newSelectedGolfTeeOuterbBox = L.circle(bBoxPolygon, {
    radius: radius + 5,
    color: "white",
    //fillOpacity: 0.1,
  }).addTo(map);

  return newSelectedGolfTeeOuterbBox;
}
