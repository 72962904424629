import React from "react";

export default class DeletePopup extends React.Component {
  render() {
    const { layer, popupRemoveLayer } = this.props;
    return (
      <div className="bt flex">
        <span
          className="db pointer bn pa2 hover-bg-light-blue"
          data-test="delete-feature"
          onClick={() => popupRemoveLayer(layer)}
        >
          Delete
        </span>
      </div>
    );
  }
}
