import React from "react";
import ReactDOM from "react-dom";
import App from "./app";
import "babel-polyfill";
import "tachyons/src/_colors.css";
import "tachyons/css/tachyons.min.css";
import "codemirror/lib/codemirror.css";
import "./css/leaflet.css";
import "./css/site.css";
import "./css/style.css";

ReactDOM.render(<App />, document.getElementById("leaflet-container"));
